import * as React from 'react';
import { alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useMediaQuery, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';

const pages = ['Documentation', 'Pricing', 'Contact', 'About'];
const settings = ['Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar({ currentPage }: { currentPage: string }) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  const { accessToken, setAccessToken, setUserSettingsState } = useAuth();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="sticky" enableColorOnDark>
      <Container maxWidth="xl" sx = {{
        height: '4rem',
      }}>
        <Toolbar disableGutters>
        <Box display={{ xs: 'none', md: 'flex' }} marginRight="8px">
          <img src={`${process.env.PUBLIC_URL}/snapi.svg`} alt="YawnLabs Logo" style={{ height: '3rem' }} />
        </Box>
          
          <Link to='/' style={{ textDecoration: 'none' }}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                padding: '2px 2px 2px 3px',
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'white',
                textDecoration: 'none',
                borderRadius: '10px',
                backgroundColor: 'transparent', 
                transition: 'background-color 0.3s ease, color 0.3s ease',
                '&:hover': {
                  backgroundColor: alpha(theme.palette.text.primary, 0.8),
                  color: theme.palette.primary.main,
                  textDecoration: 'none',
                }
              }}
            >
              YAWNLABS
            </Typography>
          </Link>

          <Box sx={{ width: 'auto', flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                width: 'auto',
                gap: '0.5rem',
              }}
            >
              {pages.map((page) => (
                <Link to={`${page}`} style={{ textDecoration: 'none' }}>
                  <MenuItem
                    key={page}
                    onClick={handleCloseNavMenu}
                    sx={{ 
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 'auto',
                      padding: '0.5rem',
                      color: theme.palette.text.primary,
                      textDecoration: currentPage === page ? 'underline' : 'none',
                      // borderRadius: '100px',
                      backgroundColor: 'transparent', 
                      transition: 'background-color 0.3s ease, color 0.3s ease',
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.text.primary, 0.8),
                        color: theme.palette.primary.main,
                        textDecoration: 'none',
                      },
                    }}
                    href={`${page}`}
                  >
                    {page}
                  </MenuItem>
              </Link>
              ))}
            </Menu>
          </Box>
          {isMobile ? 
            <Typography
              variant="h6"
              noWrap
              sx={{
                padding: '2px',
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'white',
                textDecoration: 'none',
                borderRadius: '10px',
                backgroundColor: 'transparent',
              }}
            >
              {currentPage} | 
            </Typography>
            : null }
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              padding: '2px',
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'white',
              textDecoration: 'none',
              borderRadius: '10px',
              backgroundColor: 'transparent', 
              transition: 'background-color 0.3s ease, color 0.3s ease',
              '&:hover': {
                backgroundColor: alpha(theme.palette.text.primary, 0.8),
                color: theme.palette.primary.main,
                textDecoration: 'none',
              }
            }}
          >
            YAWNLABS
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Link to={`${page}`} style={{ 
                textDecoration: 'none',
                marginRight: '0.5rem'
              }}>
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ 
                    my: 1,
                    display: 'block',
                    color: 'white',
                    textDecoration: currentPage === page ? 'underline' : 'none',
                    borderRadius: '10px',
                    backgroundColor: 'transparent', 
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.text.primary, 0.8),
                      color: theme.palette.primary.main,
                      textDecoration: 'none',
                    }
                  }}
                  href={`${page}`}
                >
                  {page}
                </Button>
              </Link>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <AccountBoxIcon sx={{ 
                  color: 'white',
                  height: '3rem', 
                  width: '3rem' }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {accessToken ? (
                settings.map((setting) => (
                  <Link to = {`/${setting.toLowerCase()}`} style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  </Link>
                ))
                ) : (
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Link to = '/login' style={{ textDecoration: 'none' }}>
                      <Typography textAlign="center">Login</Typography>
                    </Link>
                  </MenuItem>
                )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;