import { Box, Paper, Table, TableCell, TableContainer, TableRow, Typography, useTheme } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useDash } from "../../context/DashContext";
import { useQueue } from "../../context/QueueContext";
import { useParticipant } from "../../context/ParticipantContext";
import { useHistory } from "react-router-dom";
import moment from "moment";

import MUIDataTable from "mui-datatables";
import CustomToolbarSelect from "./CustomToolbarSelect";
import { fetchParticipantDevices } from "../../functions/snapi";
import { Button } from "rsuite";

const StudyDataTable: React.FC = () => {

    const { 
      studyData, selectedStudy, accessToken, setStudyData,
     } = useAuth();
    const { setSelectedParticipant } = useParticipant();
    const theme = useTheme();
    const { queue, isDrained } = useQueue();
    const { setStudyLoading } = useDash();

    const history = useHistory();

    const data = studyData.participants.map((participant) => {
        return {
            pxID: participant.lab_id,
            f_status: participant.devices.fitbit.status,
            w_status: participant.devices.withings.status,
            f_authLink: participant.devices.fitbit.auth_url,
            w_authLink: participant.devices.withings.auth_url,
            f_sleeps: participant.fitbit_sleep_stats.num_sleeps,
            w_sleeps: participant.withings_sleep_stats.num_sleeps,
            w_bps: participant.withings_meas_stats.num_bps,
            f_lastSync: participant.devices.fitbit.last_sync,
            w_lastSync: participant.devices.withings.last_sync,
            w_device_details : participant.devices.withings.details,
        }}
    )

    const columns = [
        { name: 'pxID', label: 'Participant ID',
          options: { sortThirdClickReset: true, filter: false } },
        { name: 'f_status', label: 'Fitbit Status',
          options: { 
            display: studyData.sources.includes('fitbit'),
            filter: studyData.sources.includes('fitbit'),
            download: studyData.sources.includes('fitbit'),
            sort: false, 
            customBodyRender: ( value ) => {
              return value === 'TRUE' ? (
                <span style={{ color: theme.palette.primary.main }}>Connected</span>
              ) : (
                <span style={{ color: theme.palette.secondary.main }}>Not Connected</span>
              );
            }
          } },
        { name: 'w_status', label: 'Withings Status',
          options: { 
            display: studyData.sources.includes('withings'),
            filter: studyData.sources.includes('withings'),
            download: studyData.sources.includes('withings'),
            sort: false, 
            customBodyRender: (value ) => {
              return value === 'TRUE' ? (
                <span style={{ color: theme.palette.primary.main }} data-connected="true">Connected</span>
              ) : (
                <span style={{ color: theme.palette.secondary.main }} data-connected="false">Not Connected</span>
              );
            }
          } },
        { name: 'f_sleeps', label: 'Fitbit Sleeps',
          options: { 
            display: studyData.sources.includes('fitbit'),
            download: studyData.sources.includes('fitbit'),
            filter: false
           } },
        { name: 'w_sleeps', label: 'Withings Sleeps',
          options: { 
            display: studyData.sources.includes('withings'),
            download: studyData.sources.includes('withings'),
            filter: false
         } },
        { name: 'w_bps', label: 'Withings BPs',
          options: { 
            display: studyData.sources.includes('wmeas'),
            download: studyData.sources.includes('wmeas'),
            filter: false
         } },

        { name: 'f_authLink', label: 'Fitbit Auth Link',
          options: { display: 'excluded', filter: false, download: false } },
        { name: 'w_authLink', label: 'Withings Auth Link',
            options: { display: 'excluded', filter: false, download: false } },

        { name: 'f_lastSync', label: 'Fitbit Last Sync',
          options: { 
            display: studyData.sources.includes('fitbit'),
            download: studyData.sources.includes('fitbit'),
            filter: false,
            customBodyRender: (value) => {
              if (!value) return '';
              let cleanedValue = value.replace(' GMT', '');
              let localTime = moment(cleanedValue);
              let utcTime = moment.utc(localTime);
              return value ? `${localTime.format('DD/MM/YYYY')} (${utcTime.fromNow()})` : '';
            },
            sortCompare: (order) => (row1, row2) => {
              let date1 = new Date(row1.data);
              let date2 = new Date(row2.data);
              if (order === 'asc') {
                return date1.getTime() - date2.getTime();
              } else {
                return date2.getTime() - date1.getTime();
              }
            }
          } 
        },
        { name: 'w_lastSync', label: 'Withings Last Sync',
          options: { 
            display: studyData.sources.includes('withings'),
            download: studyData.sources.includes('withings'),
            filter: false,
            customBodyRender: (value) => {
              if (!value) return '';
              let cleanedValue = value.replace(' GMT', '');
              let localTime = moment(cleanedValue);
              let utcTime = moment.utc(localTime);
              return value ? `${localTime.format('DD/MM/YYYY')} (${utcTime.fromNow()})` : '';
            }
          } 
        },
    ]

    const options = {
        filterType: 'dropdown',
        responsive: 'scroll',
        elevation: 4,
        count: studyData.participants.length,
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        pagination: false,
        fixedHeader: true,
        fixedSelectColumn: true,
        download: false,
        print: false,
        searchPlaceholder: 'Search by Participant ID',
        searchAlwaysOpen: true,
        tableBodyHeight: '100%',
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <CustomToolbarSelect 
                selectedRows={selectedRows}
                displayData={displayData}
                setSelectedRows={setSelectedRows}
                accessToken={accessToken}
                queue={queue}
                studyData={studyData}
                selectedStudy={selectedStudy}
                setStudyData={setStudyData}
                setStudyLoading={setStudyLoading}
            />
        ),
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            let data = studyData.participants.filter((p) => p.lab_id === rowData[0])[0];
            let devices = data.devices.withings.details
            if (devices === null) devices = [];
            return (
                <TableRow>
                    <TableCell colSpan={colSpan/2}>
                    <Table>
                        <TableRow>
                          <TableCell>
                              <Typography variant='h6'>
                                  {data.lab_id}
                              </Typography>
                          </TableCell>
                          <TableCell>
                            <Button 
                                onClick={() => {                          
                                    const url = `/dashboard?study=${selectedStudy}&participant=${data.lab_id}`;
                                    const baseUrl = history.location.pathname.split('/dashboard')[0];
                                    setSelectedParticipant(data.lab_id);
                                    history.push(baseUrl + url);
                                }}
                            >
                              View Participant Details
                          </Button>
                          </TableCell>
                        </TableRow>
                      {studyData.sources.includes('withings') ? (
                        <TableRow>
                          <TableCell>
                              <span 
                              style={{ 
                                  color: data.devices.withings.status === 'TRUE' ? theme.palette.primary.main : theme.palette.secondary.main,
                                  fontWeight: 'bold' }}>
                              Withings
                              </span>
                          </TableCell>
                          {devices.map((device, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                Type:
                              </TableCell>
                              <TableCell>
                                {device.type}
                              </TableCell>
                              <TableCell>
                                Battery:
                              </TableCell>
                              <TableCell>
                                {device.battery}
                              </TableCell>
                              <TableCell>
                                Last Sync:
                              </TableCell>
                              <TableCell>
                                {new Date(device.last_session_date * 1000).toLocaleString()}
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableCell>
                              <a 
                              href={data.devices.withings.auth_url}
                              target='_blank'
                              rel='noreferrer'
                              >
                              Re-Link
                              </a>
                          </TableCell>
                        </TableRow>
                      ) : null}
                      {studyData.sources.includes('fitbit') ? (
                        <TableRow>
                          <TableCell>
                              <span 
                              style={{ 
                                  color: data.devices.fitbit.status === 'TRUE' ? theme.palette.primary.main : theme.palette.secondary.main,
                                  fontWeight: 'bold' }}>
                              Fitbit
                              </span>
                          </TableCell>
                          <TableCell>
                              <a 
                              href={data.devices.fitbit.auth_url}
                              target='_blank'
                              rel='noreferrer'
                              >
                              Re-Link
                              </a>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </Table>
                    </TableCell>
                </TableRow>
            );
        }
    };

    return (
      <Box sx = {{ 
          width: '90%', height: '100%', display: 'flex',
          flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
        }}>
          <TableContainer sx={{
              borderRadius: '16px',
              overflowY: 'hidden',
              height: '90%',
              width: '100%'
          }}>
            <MUIDataTable 
                // title={selectedStudy}
                data={data}
                columns={columns}
                options = {options}
            />
          </TableContainer>
      </Box>
    );
}

export default StudyDataTable;