import { useTheme, Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

import useDocumentTitle from '../functions/useDocumentTitle';
import ResponsiveAppBar from '../components/NavBar';
import { Button } from '@mui/material';

const Home: React.FC = () => {
  useDocumentTitle('YawnLabs');
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
          <Box
            sx={{
              marginTop: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <ResponsiveAppBar currentPage='Home' />
            <Box sx={{ height: theme.spacing(2) }} />
            <Box sx={{
                paddingTop: theme.spacing(2),
                width: matches ? '30%' : '100%',
              }}
            >
              <Typography variant="h4">
                Welcome
              </Typography>

              <Box sx={{ height: theme.spacing(2) }} />
              <Typography variant="body2">
                YawnLabs is a web platform developed to assist with the collection and 
                analysis of multi-source data in sleep research and treatment.
                <br /><br />
                We provide tools to help researchers and individuals aggregate and analyze 
                sleep data from various sources, 
                including <a href="https://www.withings.com/">Withings</a>, <a href="https://www.fitbit.com/">Fitbit</a>, <a href="https://www.project-redcap.org/">REDCap</a>, and more.
                <br /><br />
                For more details about our platform, please visit our <a href="/about">about</a> page.
              </Typography>
              <Button
                  sx={{ marginTop: theme.spacing(4) }}
                  variant="contained"
                  color="inherit"
                  href="/dashboard"
              >Dashboard - Click Me
              </Button>
            </Box>
          </Box>
      );
};

export default Home;