import React, { createContext, useContext, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material';

const themeConfigurations: { [key: string]: ThemeOptions } = {
  lightmode: {
    palette: {
      mode: 'light',
      primary: {
        main: '#269152',
        light: '#5FA37B',
        dark: '#077533',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#F18444',
        dark: '#e45703',
        light: '#f7a892',
      },
      background: {
        default: '#f5f5f5',
        paper: '#FCFCFC',
      },
      info: {
        main: '#0288d1',
      },
      text: {
        primary: '#1e1e1e',
        secondary: '#6D6D6D'
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: '#5FA37B', // light primary color
              color: '#1e1e1e', // primary text color
            },
          },
        },
      },
    },
  },
  darkmode: {
    palette: {
      mode: 'dark',
      primary: {
        main: '#269152',
        light: '#5FA37B',
        dark: '#077533',
        contrastText: '#1e1e1e',
      },
      secondary: {
        main: '#F18444', 
        dark: '#e45703',
        light: '#f7a892',
      },
      text: {
        primary: '#fff', // primary text color for dark mode
        secondary: '#939393', // secondary text color
      },
      background: {
        default: '#333', // background color for dark mode
        paper: '#333', // paper color for dark mode
      }
    },
  },
};

// Create a context with a default value
const ThemeContext = createContext({
  isDarkMode: false,
  toggleDarkMode: () => {},
  setDarkMode: (value: boolean) => {}
});

// Custom hook to use the context
export const useTheme = () => useContext(ThemeContext);

export const CustomThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const userSettings = JSON.parse(sessionStorage.getItem('userSettings'));

  const [isDarkMode, setDarkMode] = useState(userSettings?.isDarkMode || false);
  const toggleDarkMode = () => setDarkMode(!isDarkMode);

  const theme = createTheme({
    ...themeConfigurations[isDarkMode ? 'darkmode' : 'lightmode'],
    typography: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: 16,
    }
  });

  React.useEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.default;
    document.body.style.color = theme.palette.text.primary;
  }, [theme]); // Dependency array, re-run the effect when theme changes

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleDarkMode, setDarkMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
