import React, { createContext, useContext, useState, ReactNode, useRef, createRef } from 'react';

interface DashContextType {
    isSidebarOpen: boolean;
    setIsSidebarOpen: (isSidebarOpen: boolean) => void;
    participantsLoading: boolean;
    setParticipantsLoading: (participantsLoading: boolean) => void;
    studyLoading: boolean;
    setStudyLoading: (studyLoading: boolean) => void;
    isCardExpanded: boolean;
    setIsCardExpanded: (isCardExpanded: boolean) => void;
    columnsList: string[];
    setColumnsList: (columnsList: string[]) => void;
    measColumnsList: string[];
    setMeasColumnsList: (measColumnsList: string[]) => void;
    numberOfDays: number;
    setNumberOfDays: (numberOfDays: number) => void;
    customDate: string;
    setCustomDate: (customDate: string) => void;
    toggleDrawer: () => void;
    toggleCard: () => void;
    chartRefs: any;
    setChartRefs: (chartRefs: any) => void;
    chartZoom: any;
    setChartZoom: (chartZoom: any) => void;
    tabIndex: number;
    setTabIndex: (tabIndex: number) => void;
}

const DashContext = createContext<DashContextType | undefined>(undefined);

interface DashProviderProps { children: ReactNode }

export const DashProvider: React.FC<DashProviderProps> = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [participantsLoading, setParticipantsLoading] = useState(false);
    const [studyLoading, setStudyLoading] = useState(false);
    const [isCardExpanded, setIsCardExpanded] = useState(false);
    const [columnsList, setColumnsList] = useState<string[]>([]);
    const [measColumnsList, setMeasColumnsList] = useState<string[]>([]);
    const [numberOfDays, setNumberOfDays] = useState<number>(0);
    const [customDate, setCustomDate] = useState('');
    const [chartRefs, setChartRefs] = useState<any>();
    const [chartZoom, setChartZoom] = useState<any>();
    const [tabIndex, setTabIndex] = useState(0);

    const toggleDrawer = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const toggleCard = () => {
      setIsCardExpanded(!isCardExpanded);
    };

  return (
    <DashContext.Provider value={{
        isSidebarOpen, setIsSidebarOpen, participantsLoading, setParticipantsLoading,
        studyLoading, setStudyLoading,
        isCardExpanded, setIsCardExpanded, columnsList, setColumnsList,
        measColumnsList, setMeasColumnsList, numberOfDays, setNumberOfDays,
        customDate, setCustomDate, toggleDrawer, toggleCard,
        chartRefs, setChartRefs, chartZoom, setChartZoom,
        tabIndex, setTabIndex
      }}>
      {children}
    </DashContext.Provider>
  );
};

export const useDash = () => {
  const context = useContext(DashContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
