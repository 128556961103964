import { Box, Button, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useDocumentTitle from '../functions/useDocumentTitle';
import { useAuth } from '../context/AuthContext';
import ResponsiveAppBar from '../components/NavBar';
import { fetchStudies } from '../functions/snapi';

const Account: React.FC = () => {
  useDocumentTitle('YawnLabs | Account');
  const theme = useTheme();
  const history = useHistory();
  const { accessToken, userSettings, studies, setStudies, setSelectedStudy } = useAuth();

  useEffect(() => {
      fetchStudies({ accessToken, setStudies });
  }, [accessToken, setStudies]);

  return (
        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ResponsiveAppBar currentPage='Account' />
          <Box sx={{
              paddingTop: theme.spacing(2)
            }}
          >
            <Typography variant="h4">Account</Typography>

            <Typography variant="h6">User Settings</Typography>
            <Typography>{JSON.stringify(userSettings)}</Typography>

            <Typography variant="h6">Studies</Typography>
            <Grid container justifyContent="center" alignItems="center" sx={{ width: '50%', margin: 'auto' }}>
              {studies.map((study) => (
                <Grid item key={study} sx={{ padding: '0.2rem' }}>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => {
                      setSelectedStudy(study);
                      history.push('/dashboard');
                    }}
                  >
                    {study}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
    );
};

export default Account;