import React, { useEffect, useState } from 'react';
import { Box, IconButton, Skeleton, Typography, useTheme, alpha } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS,
  BarController, LineController, CategoryScale,
  LinearScale, TimeScale, BarElement, PointElement,
  LineElement, Tooltip
} from 'chart.js';

import { useParticipant } from '../../context/ParticipantContext';
import { useAuth } from '../../context/AuthContext';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { fetchParticipantEpochData } from '../../functions/snapi';

ChartJS.register(BarController, LineController, CategoryScale,
    LinearScale, TimeScale, BarElement, PointElement,
    LineElement, Tooltip
);

interface DailyProps {
    loading: loading;
}

interface loading {
    dateLoading: boolean;
    setDateLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const Daily: React.FC<DailyProps> = ({loading}) => {
    const theme = useTheme();
    const history = useHistory();

    const { selectedParticipant, selectedDate, setSelectedDate, participantSleepData } = useParticipant();
    const { accessToken, selectedStudy } = useAuth();

    const [ dateSummaryData, setDateSummaryData ] = useState<any>(null);
    const [ dateData, setDateData ] = useState<any>(null);
    const [ formattedEpochData, setFormattedEpochData ] = useState<any>(null);

    useEffect(() => {
        const getDateData = () => {
            let date = selectedDate.toISOString().split('T')[0];
            let data = participantSleepData.filter((item: any) => {
                let itemDate = new Date(item.w_date);
                let formattedItemDate = itemDate.toISOString().split('T')[0];
                return formattedItemDate === date;
            });
            setDateSummaryData(data);
            
            if (data.length === 0) {
                return;
            }

            console.log('Data start')
            console.log(data);
            
            const epochData = fetchParticipantEpochData({
                accessToken,
                participantID: selectedParticipant,
                w_id: data[0].w_id,
                setDateData,
                setDateLoading: loading.setDateLoading
            });
        }

        getDateData();
    }, [selectedDate, participantSleepData]);

    useEffect(() => {
        if (dateData) {
            console.log('Date data start');
            console.log(dateData);
            
            const series = dateData.body.series;
            // sort series by series[item].enddate
            series.sort((a: any, b: any) => a.enddate - b.enddate);

            let epochTimes: any = [];
            let epochStates: any = [];
            let epochHr: any = [];
            let epochRr: any = [];
            let epochHrv_rmssd: any = [];
            let epochHrv_sdnn: any = [];
            series.forEach((item: any) => {
                const start = item.startdate;
                const end = item.enddate;
                const state = item.state;
                const duration = (end - start) / 60;
                for (let i = 0; i < duration; i++) {
                    epochStates.push(state);
                    epochTimes.push((start + i * 60) * 1000);
                }

                if (item.hr) {
                    Object.keys(item.hr).forEach((key: any) => {
                        epochHr.push({x: key * 1000, y: item.hr[key]});
                    });
                }

                if (item.rr) {
                    Object.keys(item.rr).forEach((key: any) => {
                        epochRr.push({x: key * 1000, y: item.rr[key]});
                    });
                }

                if (item.rmssd) {
                    Object.keys(item.rmssd).forEach((key: any) => {
                        epochHrv_rmssd.push({x: key * 1000, y: item.rmssd[key]});
                    });
                }

                if (item.sdnn_1) {
                    Object.keys(item.sdnn_1).forEach((key: any) => {
                        epochHrv_sdnn.push({x: key * 1000, y: item.sdnn_1[key]});
                    });
                }
                
            });
            const epochData = {
                'sleep': {
                    labels: epochTimes,
                    datasets: [
                        {
                            label: 'Sleep',
                            data: epochStates,
                            backgroundColor: "#457b9d",
                            borderColor: "#457b9d",
                            borderWidth: 2,
                            stepped: true,
                            pointStyle: false,
                        }
                    ]
                },
                'hr': {
                    labels: epochTimes,
                    datasets: [
                        {
                            label: 'HR',
                            data: epochHr,
                            backgroundColor: "#ae2012",
                            borderColor: "#ae2012",
                            borderWidth: 2,
                            pointStyle: false,
                        }
                    ]
                },
                'rr': {
                    labels: epochTimes,
                    datasets: [
                        {
                            label: 'RR',
                            data: epochRr,
                            backgroundColor: alpha(theme.palette.primary.main, 0.5),
                            borderColor: theme.palette.primary.main,
                            borderWidth: 2,
                            pointStyle: false,
                        },
                    ]
                },
                'hrv': {
                    labels: epochTimes,
                    datasets: [
                        {
                            label: 'RMSSD',
                            data: epochHrv_rmssd,
                            backgroundColor: alpha(theme.palette.secondary.dark, 0.8),
                            borderColor: alpha(theme.palette.secondary.dark, 0.8),
                            borderWidth: 2,
                            pointStyle: false,
                        },
                        {
                            label: 'SDNN',
                            data: epochHrv_sdnn,
                            backgroundColor: alpha(theme.palette.secondary.light, 0.8),
                            borderColor: alpha(theme.palette.secondary.light, 0.8),
                            borderWidth: 2,
                            pointStyle: false,
                        }
                    ]
                }
            }
            console.log(epochData);
            setFormattedEpochData(epochData);
        }
    }, [dateData]);

    const back = () => {
        history.push(`/dashboard?study=${selectedStudy}&participant=${selectedParticipant}`);
        setSelectedDate(null)
    };

    const nextDay = () => {
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() + 1);
        const dateStr = newDate.toISOString().split('T')[0];
        history.push(
            `/dashboard?study=${selectedStudy}&participant=${selectedParticipant}&date=${dateStr}`
        );
        setSelectedDate(newDate);
    };

    const prevDay = () => {
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() - 1);
        const dateStr = newDate.toISOString().split('T')[0];
        history.push(
            `/dashboard?study=${selectedStudy}&participant=${selectedParticipant}&date=${dateStr}`
        );
        setSelectedDate(newDate);
    };

    ChartJS.defaults.color = theme.palette.text.primary;

    return (
      <Box sx={{
        width: '100%', display: 'flex', flexDirection: 'column',
        alignItems: 'center', justifyContent: 'center',
      }}>
        <Box 
            sx={{ 
                display: 'flex', alignItems: 'center', 
                justifyContent: 'space-between', width: '25%', 
                paddingTop: '0.1%', paddingBottom: '0%',
                marginBottom: '0%'
            }}
        >
            <IconButton size="small" onClick={prevDay}>
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6">
                {selectedDate.toDateString()}
            </Typography>
            <IconButton size="small" onClick={nextDay}>
                <ArrowForwardIcon />
            </IconButton>
        </Box>
        {formattedEpochData && !loading.dateLoading ? (
        <Box style={{ 
            width: '90%', height: '80vh', 
            overflowY: 'scroll', overflowX: 'hidden',
            display: 'flex', alignItems: 'center', 
            justifyContent: 'center', flexDirection: 'column',
        }} id={'epoch_charts'}>
            <Box style={{ 
                display: 'flex', width: '100%', height: '20vh',
                borderRadius: '1vh', alignItems: 'center', justifyContent: 'center',
                padding: '1vh', boxSizing: 'border-box', marginBottom: '1vh'
            }} id={'epoch_chart'}>
                <Chart
                    type={'line'}
                    data={formattedEpochData['sleep']}
                    options={{
                        interaction: {
                            mode: 'index',
                            intersect: false,
                        },
                        scales: {
                            y: {
                                ticks: {
                                    callback: function(value: any, index: any, values: any) {
                                        return value === 0 ? 'Awake' : value === 1 ? 'Light' : value === 2 ? 'Deep' : value === 3 ? 'REM': '';
                                    }
                                },
                                grid: {
                                    display: false
                                }
                            },
                            x: {
                                grid: {
                                    display: false
                                },
                                ticks: {
                                    callback: function(value: any, index: any, values: any) {
                                        const date = new Date(value);
                                        const dateStr = date.toTimeString().split(':00 ')[0];
                                        return dateStr;
                                    }
                                },
                                type: 'time',
                                time: {
                                    unit: 'minute',
                                    displayFormats: {
                                        minute: 'HH:mm'
                                    }
                                }
                            }
                        },
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function(context: any) {
                                        if (!context.parsed) {
                                            return '';
                                        }
                                        const epoch = context.parsed.x;
                                        const date = new Date(epoch);
                                        const dateStr = date.toTimeString().split(':00 ')[0];
                                        return dateStr + ': ' + (context.parsed.y === 0 ? 'Awake' : context.parsed.y === 1 ? 'Light Sleep' : context.parsed.y === 2 ? 'Deep Sleep' : context.parsed.y === 3 ? 'REM Sleep': '');
                                    },
                                    title: function(context: any) {
                                        return '';
                                    }
                                }
                            
                            },
                            legend: {
                                display: false
                            },
                        },
                        maintainAspectRatio: false,
                        animation: false
                    }}
                />
            </Box>
            <Box style={{ 
                display: 'flex', width: '100%', height: '20vh',
                borderRadius: '1vh', alignItems: 'center', justifyContent: 'center',
                padding: '1vh', boxSizing: 'border-box', marginBottom: '1vh'
            }} id={'epoch_chart'}>
                <Chart
                    type={'line'}
                    data={formattedEpochData['rr']}
                    options={{
                        interaction: {
                            mode: 'index',
                            intersect: false,
                        },
                        scales: {
                            x: {
                                grid: {
                                    display: false
                                },
                                ticks: {
                                    callback: function(value: any, index: any, values: any) {
                                        const date = new Date(value);
                                        const dateStr = date.toTimeString().split(':00 ')[0];
                                        return dateStr;
                                    }
                                },
                                type: 'time',
                                time: {
                                    unit: 'minute',
                                    displayFormats: {
                                        minute: 'HH:mm'
                                    }
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: false
                            },
                            title: {
                                display: true,
                                text: 'Respiratory Rate'
                            }
                        },
                        maintainAspectRatio: false,
                        animation: false
                    }}
                />
            </Box>
            <Box style={{ 
                display: 'flex', width: '100%', height: '20vh',
                borderRadius: '1vh', alignItems: 'center', justifyContent: 'center',
                padding: '1vh', boxSizing: 'border-box', marginBottom: '1vh'
            }} id={'epoch_chart'}>
                <Chart
                    type={'line'}
                    data={formattedEpochData['hr']}
                    options={{
                        interaction: {
                            mode: 'index',
                            intersect: false,
                        },
                        scales: {
                            x: {
                                grid: {
                                    display: false
                                },
                                ticks: {
                                    callback: function(value: any, index: any, values: any) {
                                        const date = new Date(value);
                                        const dateStr = date.toTimeString().split(':00 ')[0];
                                        return dateStr;
                                    }
                                },
                                type: 'time',
                                time: {
                                    unit: 'minute',
                                    displayFormats: {
                                        minute: 'HH:mm'
                                    }
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: false
                            },
                            title: {
                                display: true,
                                text: 'Heart Rate'
                            }
                        },
                        maintainAspectRatio: false,
                        animation: false
                    }}
                />
            </Box>
            <Box style={{ 
                display: 'flex', width: '100%', height: '20vh',
                borderRadius: '1vh', alignItems: 'center', justifyContent: 'center',
                padding: '1vh', boxSizing: 'border-box', marginBottom: '1vh'
            }} id={'epoch_chart'}>
                <Chart
                    type={'line'}
                    data={formattedEpochData['hrv']}
                    options={{
                        interaction: {
                            mode: 'index',
                            intersect: false,
                        },
                        scales: {
                            x: {
                                grid: {
                                    display: false
                                },
                                ticks: {
                                    callback: function(value: any, index: any, values: any) {
                                        const date = new Date(value);
                                        const dateStr = date.toTimeString().split(':00 ')[0];
                                        return dateStr;
                                    }
                                },
                                type: 'time',
                                time: {
                                    unit: 'minute',
                                    displayFormats: {
                                        minute: 'HH:mm'
                                    }
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: false
                            },
                            title: {
                                display: true,
                                text: 'Heart Rate Variability (RMSSD/SDNN1)'
                            }
                        },
                        maintainAspectRatio: false,
                        animation: false
                    }}
                />
            </Box>
        </Box>
      ) : 
        <>
          <Skeleton 
            variant="rectangular" 
            width={'90%'} height={'20vh'}
            style={{ borderRadius: '1vh' }}
          />
        </>}
      </Box>
    );
};

export default Daily;