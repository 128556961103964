import jsPDF from 'jspdf';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-date-fns';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, BarController, LineController, CategoryScale,
    LinearScale, TimeScale, BarElement, PointElement,
    LineElement, Tooltip } from 'chart.js';
import { alpha } from '@mui/material';
import DataChart from '../components/charts/data_withings';
import ReactDOMServer from 'react-dom/server';
import html2canvas from 'html2canvas';

ChartJS.register(BarController, LineController, CategoryScale,
  LinearScale, TimeScale, BarElement, PointElement,
  LineElement, annotationPlugin
);

export async function generateAndDownloadPDF(
  summaryData: any, chartRefs: any
) {
  const doc = new jsPDF({
    unit: 'pt',        // Using points for precise control
    format: 'a4',      // A4 format
    orientation: 'portrait', // Portrait mode
  });

  // A4 page dimensions in points
  const a4Width = 595.28;
  const a4Height = 841.89;
  const margin = 20;
  const headerHeight = 50; // Height for the header

  // Function to add header on each page
  function addHeader(doc, participantID) {
    doc.setFillColor('#5FA37B'); // Light green color
    doc.rect(0, 0, a4Width, headerHeight, 'F'); // Draw filled rectangle
    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0); // Black text color

    const text = `Patient Report - ${participantID}`;
    const textWidth = doc.getTextWidth(text);
    const x = (a4Width - textWidth) / 2; // Calculate x-coordinate to center the text

    doc.text(text, x, 30); // Add centered text
  }

  // Create an off-screen div for rendering
  const offScreenDiv = document.createElement('div');
  offScreenDiv.style.position = 'absolute';
  offScreenDiv.style.left = '-9999px';
  document.body.appendChild(offScreenDiv);

  // Create an HTML element for the summary information with styling
  const summaryElement = document.createElement('div');
  summaryElement.style.border = '2px solid #1f1f1f';
  summaryElement.style.borderRadius = '10px';
  summaryElement.style.padding = '10px';
  summaryElement.style.margin = '10px';
  summaryElement.style.fontFamily = 'Roboto, sans-serif, Arial, Helvetica, sans-serif';
  summaryElement.style.width = `${a4Width - 2 * margin}px`;
  summaryElement.style.height = '100%';
  summaryElement.style.boxSizing = 'border-box';

  // Add summary data with icons
  summaryElement.innerHTML = `
    <style>
      table {
        width: 100%;
        height: 100%;
        border-collapse: collapse;
      }
      th, td {
        border: 1px solid #1f1f1f;
        text-align: left;
        padding: 8px;
        font-size: 12pt;
      }
      th {
        background-color: #b7b7b7;
        font-size: 14pt;
      }
    </style>
    <table>
      <tr>
        <th>Participant</th>
        <th>${summaryData.participantID}</th>
      </tr>
      <tr>
        <td>Average In Bed Time</td>
        <td>${summaryData.meanInBed}</td>
      </tr>
      <tr>
        <td>Average Wake Time</td>
        <td>${summaryData.meanAwake}</td>
      </tr>
      <tr>
        <td>Sleep Duration</td>
        <td>${Math.round(summaryData.meanTST)} hours</td>
      </tr>
      <tr>
        <td>Sleep Efficiency</td>
        <td>${Math.round(summaryData.meanSE)}%</td>
      </tr>
      <tr>
        <td>Sleep Onset Latency</td>
        <td>${Math.round(summaryData.meanSOL)} minutes</td>
      </tr>
      <tr>
        <td>Wake After Sleep Onset</td>
        <td>${Math.round(summaryData.meanWASO)} minutes</td>
      </tr>
      <tr>
        <td>Heart Rate</td>
        <td>${Math.round(summaryData.meanHR)} beats / minute</td>
      </tr>
      <tr>
        <td>Respiratory Rate</td>
        <td>${Math.round(summaryData.meanHR)} breaths / minute</td>
      </tr>
      <tr>
        <td>Apnea Hypopnea Index</td>
        <td>${Math.round(summaryData.meanAHI)} events per hour</td>
      </tr>
      <tr>
        <td>Mean Blood Pressure</td>
        <td>${Math.round(summaryData.meanSBP)}/${Math.round(summaryData.meanDBP)} mmHg</td>
      </tr>
    </table>
  `;

  // Append the summary element to the body temporarily for rendering
  offScreenDiv.appendChild(summaryElement);

  // Render the HTML to a canvas with scaling to fit A4
  let scale = 3;  // Higher scale for better resolution
  const summaryCanvas = await html2canvas(summaryElement, {
    scale: scale,
    width: a4Width - 2 * margin,
  });
  const summaryImgData = summaryCanvas.toDataURL('image/png');

  const pID = summaryData.participantID;
  
  // Add header and summary to the first page
  addHeader(doc, pID); // Add header to new page
  doc.addImage(summaryImgData, 'PNG', margin, headerHeight + margin, a4Width - 2 * margin, 0);

  document.body.removeChild(offScreenDiv);
  doc.addPage();

  addHeader(doc, pID); // Add header to new page
  let yPos = headerHeight + margin;

  const chartElements = document.querySelectorAll('.p-chart');
  scale = 1.5;
  for (let i = 0; i < chartElements.length; i++) {
    // Assert that each element is an HTMLElement
    const element = chartElements[i] as HTMLElement;
    const elementID = element.id;
    const chartRef = chartRefs[elementID];

    // Ensure chartRef and chartRef.canvas exist before proceeding
    if (chartRef && chartRef.canvas) {
      // Adjust chart size to fit A4 page width while maintaining aspect ratio
      const newWidth = scale * (a4Width - 2 * margin);
      const newHeight = scale * 200;

      // Resize the chart for PDF rendering
      chartRef.resize(newWidth, newHeight);

      // Generate the base64 image of the chart
      const imgData = chartRef.toBase64Image('image/png', 1);

      // Calculate the scaled dimensions for the PDF
      const pdfWidth = newWidth / scale;
      const pdfHeight = newHeight / scale;

      // Check if a new page is needed
      if (yPos + pdfHeight > doc.internal.pageSize.getHeight()) {
        doc.addPage();
        addHeader(doc, summaryData.participantID); // Add header to new page
        yPos = headerHeight + margin;
      }

      // Add the chart image to the PDF
      doc.addImage(imgData, 'PNG', margin, yPos, pdfWidth, pdfHeight);
      yPos += pdfHeight + margin;

      // Optionally reset the chart size back to its original dimensions
      chartRef.canvas.style.width = '';
      chartRef.canvas.style.height = '';

      chartRef.resize();
    } else {
      console.error(`Chart reference or canvas is null for element ID: ${elementID}`);
    }
  }

  // Download the PDF after all charts have been added
  doc.save('participant-summary.pdf');
}