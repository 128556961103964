import axios from 'axios';
import { setDate } from 'date-fns';
import download from 'downloadjs';

interface wo_item {
    lab_id: any;
    wo_date: any;
    [key: string]: any;
  }

export const setDashPreferences = async ({ accessToken, data }) => {
    try {
        const response = await axios.post('https://snapi.space/api/users/dashboard', data, {
            headers: { 'Authorization': `Bearer ${accessToken}` },
        });
        console.log('Preferences updated:', response.data);
    } catch (error) {
        console.error('Error updating preferences:', error);
    }
}

export const fetchDashPreferences = async ({ accessToken }) => {
    try {
        const response = await axios.get('https://snapi.space/api/users/dashboard', {
            headers: { 'Authorization': `Bearer ${accessToken}` },
        });
        console.log('Preferences fetched:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching preferences:', error);
    }
}

export const fetchStudies = async ({ accessToken, setStudies }) => {
    try {
        const response = await axios.get('https://snapi.space/api/me', {
            headers: { 'Authorization': `Bearer ${accessToken}` },
        });
        const studies = response.data['studies'] || [];
        studies.sort((a: string, b: string) => a.localeCompare(b));

        setStudies(studies);
        return 201;
    } catch (error) {
        console.error('Error fetching studies:', error);
        // If error, return an error code
        return 500;
    }
}

export const fetchParticipantData = async ({ 
    accessToken, setLoadingParticipantData, selectedParticipant,
    setParticipantData, setParticipantSleepData, setParticipantMeasData,
    setColumnsList, setMeasColumnsList
 }) => {
    
    setLoadingParticipantData(true);
    axios.get(`https://www.snapi.space/api/participant/${selectedParticipant}/withings/summarise`, {
        headers: { 'Authorization': `Bearer ${accessToken}` },
    })
    .then(response => {
        if (response.data.sleep_data) {
            const fieldsToParse = ['mean_tst', 'mean_ahi', 'mean_se', 'mean_tib'];
            fieldsToParse.forEach(field => {
                if (typeof response.data.sleep_data[field] === 'string') {
                    response.data.sleep_data[field] = parseFloat(response.data.sleep_data[field]);
                    if (field === 'mean_tst' || field === 'mean_tib') {
                        response.data.sleep_data[field] /= 3600;
                    }
                }
            });
        }
        console.log("HERE")
        console.log(response.data)
        setParticipantData(response.data); // Adjust based on your actual response structure
    })
    .catch(error => {
        console.error('Error fetching participant data:', error);
    })

    axios.get(`https://www.snapi.space/api/participant/${selectedParticipant}/withings/meas`, {
        headers: { 'Authorization': `Bearer ${accessToken}` },
    })
    .then(response => {
        // convert response.data.w_total_sleep_time, w_lightsleepduration, w_deepsleepduration, w_rem_sleep_duration to minutes (from seconds)
        response.data.forEach((item: any) => {
            // Convert response.data.wo_date from a unit timestamp to a date
            item.wo_date = new Date(item.wo_date * 1000).toISOString().split('T')[0];
        });

        response.data.sort((a: any, b: any) => {
            return new Date(a.wo_date).getTime() - new Date(b.wo_date).getTime();
        });

        // Reformat from records with lab_id, wo_date, wo_type, and wo_value columns to records with lab_id, wo_date, and each wo_type as columns, with wo_value as the values for each wo_type
        //extract only necessary columns from response data
        const newResponseData: any[] = [];
        response.data.forEach((item: any) => {
            const existingItem = newResponseData.find((newItem: any) => newItem.wo_date === item.wo_date);
            if (existingItem) {
                existingItem[item.wo_type] = item.wo_value;
            } else {
                const newItem: wo_item = { lab_id: item.lab_id, wo_date: item.wo_date };
                newItem[item.wo_type] = item.wo_value;
                newResponseData.push(newItem);
            }
        });

        // ensure that all records in newResponseData have the same columns
        const columns = new Set<string>();
        newResponseData.forEach((item: any) => {
            Object.keys(item).forEach(key => columns.add(key));
        });
        columns.forEach(column => {
            newResponseData.forEach((item: any) => {
                if (!item[column]) {
                    item[column] = null;
                }
            });
        });

        // drop column 'nan' from newResponseData if it exists
        const nanIndex = newResponseData.findIndex((item: any) => item.hasOwnProperty('nan'));
        if (nanIndex > -1) {
            newResponseData.splice(nanIndex, 1);
        }

        setParticipantMeasData(newResponseData);
        // Get columns list and append columns from newResponseData to columnsList, except for 'lab_id' and 'wo_date'
        const measColumnsList = newResponseData.length > 0 ? Object.keys(newResponseData[0]) : [];
        // Remove unwanted columns from the list
        const unwantedColumns = ['lab_id', 'wo_date'];
        unwantedColumns.forEach(column => {
            measColumnsList.splice(measColumnsList.indexOf(column), 1);
        });
        setMeasColumnsList(measColumnsList);
    })
    .catch(error => {
        console.error('Error fetching participant data:', error);
    })
    
    axios.get(`https://www.snapi.space/api/participant/${selectedParticipant}/withings/nights`, {
        headers: { 'Authorization': `Bearer ${accessToken}` },
    }).then(response => {
        response.data.sort((a: any, b: any) => {
            return new Date(a.w_enddate).getTime() - new Date(b.w_enddate).getTime();
        });

        // remove items where w_apnea_hypopnea_index is -1 
        response.data = response.data.filter((item: any) => item.w_apnea_hypopnea_index !== -1)
        const newColumnsList = response.data.length > 0 ? Object.keys(response.data[0]) : [];
        
        // Add additional column for sleep_timing, which contains w_startdate and w_enddate
        // newColumnsList.push('w_sleep_timing');
        response.data.forEach((item: any) => {
            item.w_sleep_timing = [item.w_startdate, item.w_enddate];
        });

        // Remove unwanted columns from the list
        const unwantedColumns = ['id', 'lab_id', 'participant_id', 'w_enddate', 'w_startdate', 'w_created', 'w_date', 'w_hash_deviceid', 'w_modified', 'w_id', 'w_model', 'w_model_id', 'w_night_events', 'w_timezone'];
        unwantedColumns.forEach(column => {
            newColumnsList.splice(newColumnsList.indexOf(column), 1);
        });

        const duration_items = [
            'w_total_sleep_time',
            'w_total_timeinbed',
            'w_lightsleepduration',
            'w_deepsleepduration',
            'w_rem_sleep_duration',
            'w_snoring',
            'w_wakeup_latency',
            'w_waso',
            'w_sleep_latency'
        ];

        // Convert duration items from seconds to minutes
        response.data.forEach((item: any) => {
            duration_items.forEach(duration_item => {
                if (item[duration_item] !== null) {
                    item[duration_item] /= 60;
                    if (duration_item === 'w_total_sleep_time' || duration_item === 'w_total_timeinbed') {
                        item[duration_item] /= 60;
                    }
                }
            });
        });

        // Convert w_sleep_efficiency from a decimal to a percentage
        response.data.forEach((item: any) => {
            if (item.w_sleep_efficiency !== null) {
                item.w_sleep_efficiency *= 100;
            }
        });

        setParticipantSleepData(response.data); // Adjust based on your actual response structure
        setColumnsList(newColumnsList);
    }).catch(error => {
        console.error('Error fetching participant data:', error);
    }).finally(() => {
        setLoadingParticipantData(false);
    });
}

export const fetchParticipantEvents = async ({ accessToken, selectedParticipant, setParticipantEventData }) => {

    axios.get(`https://www.snapi.space/api/participant/events/${selectedParticipant}`, {
        headers: { 'Authorization': `Bearer ${accessToken}` },
    })
    .then(response => {
        setParticipantEventData(response.data); // Adjust based on your actual response structure
    })
    .catch(error => {
        console.error('Error fetching participant data:', error);
    })
}

export const fetchParticipants = async ({ accessToken, setParticipantsLoading, setParticipants, selectedStudy }) => {
    try {
        const response = await axios.get(`https://www.snapi.space/api/study/${selectedStudy}`, {
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        },
        });
        const participantIds = response.data['participants']['lab_ids'];
        // Sort the participant IDs, first by checking for any numbers in the string, then by the string itself
        participantIds.sort((a: string, b: string) => {
            const aNum = a.match(/\d+/);
            const bNum = b.match(/\d+/);
            if (aNum && bNum) {
                return parseInt(aNum[0]) - parseInt(bNum[0]);
            } else {
                return a.localeCompare(b);
            }
        });

        setParticipants(participantIds);
    } catch (error) {
        console.error('Error fetching participants:', error);
        setParticipants([]); // Reset participants list on error
    } finally {
        setParticipantsLoading(false);
    }
}

export const fetchStudyData = async ({ accessToken, selectedStudy, setStudyData, setStudyLoading }) => {
    try {
        const response = await axios.get(`https://www.snapi.space/api/study/${selectedStudy}/summary`, {
            headers: { 'Authorization': `Bearer ${accessToken}` },
        });
        let data = response.data;
        if (typeof data === 'string') {
            data = data.replace(/NaN/g, '"NaN"');
            data = JSON.parse(data);
        }
        console.log(data)
        setStudyData(data);
        setStudyLoading(false)
    } catch (error) {
        console.error('Error fetching study data:', error);
    }
}

export const RefreshPx = async ({ accessToken, pxID, source, queue }) => {
    console.log("Refreshing px")
    console.log(source)
    queue.add(async () => {
        if (source === 'withings' || source === 'fitbit') {
            try {
                await axios.get(`https://snapi.space/api/participant/${pxID}/${source}/get_devices`, {
                    headers: { 'Authorization': `Bearer ${accessToken}` },
                });

                const response = await axios.post(`https://snapi.space/api/participant/${pxID}/${source}/nights`, {}, {
                    headers: { 'Authorization': `Bearer ${accessToken}` },
                });
                return response.data;
            } catch (error) {
                console.error('Error refreshing participant:', error);
            }
        }
        if (source === 'wmeas') {
            try {
                const response = await axios.post(`https://snapi.space/api/participant/${pxID}/withings/meas`, {}, {
                        headers: { 'Authorization': `Bearer ${accessToken}` },
                });
                return response.data;
            } catch (error) {
                console.error('Error refreshing participant:', error);
            }
        }
    });
}

export const QueueFetchStudyData = async ({ accessToken, selectedStudy, setStudyData, setStudyLoading, queue }) => {
    queue.add(async () => {
        try {
            const response = await axios.get(`https://www.snapi.space/api/study/${selectedStudy}/summary`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            });
            let data = response.data;
            if (typeof data === 'string') {
                data = data.replace(/NaN/g, '"NaN"');
                data = JSON.parse(data);
            }
            setStudyData(data); // Adjust based on your actual response structure
            setStudyLoading(false)
        } catch (error) {
            console.error('Error fetching study data:', error);
        }
    });
}

export const ExportPxs = async ({ accessToken, pxs, source, queue }) => {
    queue.add(async () => {
        await axios.post(
            `https://snapi.space/api/participant/export/${source}`,
            {participant_ids: pxs},
            {
                headers: { 'Authorization': `Bearer ${accessToken}` },
                responseType: 'blob'
            }
        ).then((response) => {
            const contentDisposition = response.headers['content-disposition'];
            let filename = `${source}.csv`;  // default filename
            // Extract filename from Content-Disposition header if present
            if (contentDisposition) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            download(response.data, filename);
        }).catch((error) => {
            console.error(error);
        });
    });
}

export const fetchParticipantDevices = async ({ accessToken, participantID, sources }) => {
    const devices = {};
    sources.forEach(async (source) => {
        try {
            const response = await axios.get(`https://snapi.space/api/participant/${participantID}/${source}/get_devices`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            });
            devices[source] = response.data;
        } catch (error) {
            console.error(`Error fetching ${source} devices:`, error);
        }
    });
    return devices;
}

export const fetchParticipantEpochData = async ({ accessToken, participantID, w_id, setDateData, setDateLoading }) => {
    let data = {};
    setDateLoading(true);
    try {
        const response = await axios.get(`https://snapi.space/api/participant/${participantID}/withings/epoch/${w_id}`, {
            headers: { 'Authorization': `Bearer ${accessToken}` },
        });
        data = response.data;
    } catch (error) {
        console.error(`Error fetching data:`, error);
    } finally {
        setDateData(data);
        setDateLoading(false);
    }
    return data;
}

export const addParticipant = async ({ accessToken, participantID, studyName }) => {
    const url = `https://snapi.space/api/participant`
    const json = {
        lab_id: participantID,
        study_name: studyName
    }
    try {
        const response = await axios.post(url, json, {
            headers: { 'Authorization': `Bearer ${accessToken}` },
        });
        console.log('Participant added:', response.data);
    } catch (error) {
        console.error('Error adding participant:', error);
    } 
}
export const deleteParticipant = async ({ accessToken, participantID }) => {
    const url = `https://snapi.space/api/participant/${participantID}`

    try {
        const response = await axios.delete(url, {
            headers: { 'Authorization': `Bearer ${accessToken}` },
        });
        console.log('Participant deleted:', response.data);
    } catch (error) {
        console.error('Error adding participant:', error);
    } 
}