import '@fontsource/roboto';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import Providers from './Providers'
import Login from './pages/Login';
import Home from './pages/Home'; 
import Dashboard from './pages/Dashboard'; 
import About from './pages/About';
import Account from './pages/Account';
import Pricing from './pages/Pricing';
import FloatingSettingsButton from './components/FloatingSettingsButton';
import PrivateRoute from './components/PrivateRoute';

import { useHistory } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import Documentation from './pages/Documentation';

const Logout: React.FC = () => {
  const history = useHistory();
  const { setAccessToken, setUserSettingsState } = useAuth();

  const handleLogout = () => {
    setAccessToken(null); // Clear the access token from context
    setUserSettingsState(null);
    history.push('/'); // Redirect to the login page
    console.log('Logged out');
  };

  useEffect(() => {
    handleLogout();
  }, []); // Call handleLogout when the component is rendered

  return null; // This component doesn't need to render anything
};

function App() {
  return (
    <Providers>
      <Router>
        <div className="App">
          <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            <Route path="/" exact><Home /></Route>
            <Route path="/documentation"><Documentation /></Route>
            <Route path="/about"><About /></Route>
            <Route path="/pricing"><Pricing /></Route>
            <PrivateRoute path="/account"><Account /></PrivateRoute>
            <Route path="/logout" component={Logout} />
            <Redirect from="*" to="/" />
          </Switch>
          <FloatingSettingsButton />
        </div>
      </Router>
    </Providers>
  );
}

export default App;
