import { 
  IconButton, Divider, MenuItem, Box, AppBar, alpha,
  Button, Menu, Typography, useTheme, useMediaQuery,
  Toolbar, styled, Autocomplete, TextField
} from "@mui/material"

import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from '@mui/material/Tooltip';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

import { useParticipant } from '../context/ParticipantContext';
import { useDash } from "../context/DashContext";
import { Link } from "react-router-dom";
import React, { useRef, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { set } from "date-fns";

const settings = ['Account', 'Dashboard', 'Logout'];

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '1rem',
  backgroundColor: alpha(theme.palette.text.primary, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.text.primary, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const DashNavBar: React.FC = () => {
    const { accessToken, studies, selectedStudy, setSelectedStudy, participants } = useAuth();
    const { setSelectedParticipant, selectedParticipant, selectedDate, setSelectedDate } = useParticipant();
    const { setTabIndex, tabIndex } = useDash();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorStud, setAnchorStud] = useState(null);
    const open = Boolean(anchorEl);

    const menuItems = [
      { label: 'Sleep Measurements', value: 1 },
      { label: 'BP Measurements', value: 2 },
      { label: 'Weight Measurements', value: 3 },
      { label: 'BP Trend (test)', value: 4 },
    ];

    const dashHome = () => {
      setSelectedStudy(null);
      setSelectedParticipant(null);
      // remove study and participant arguments from url
      const url = window.location.href.split('?')[0];
      window.history.pushState({}, '', url);
    }

    const changeStudy = (study) => {
      setSelectedStudy(study);
      setSelectedParticipant(null);
      setSelectedDate(null);
      let url = window.location.href.split('?')[0];
      url = `${url}?study=${study}`;
      window.history.pushState({}, '', url);
    }

    const changeParticipant = (participant) => {
      setSelectedParticipant(participant);
      setSelectedDate(null);
      let url = window.location.href.split('?')[0];
      url = `${url}?study=${selectedStudy}&participant=${participant}`;
      window.history.pushState({}, '', url);
    }

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleClickStudies = (event) => {
      setAnchorStud(event.currentTarget);
    };
  
    const handleCloseStudies = () => {
      setAnchorStud(null);
    };
  
    const handleMenuItemClick = (index) => {
      setTabIndex(index);
      handleClose();
    };

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };

    function handleAutocompleteChange(event, value) {
      setSelectedParticipant(value);
      setSelectedDate(null);
      let url = window.location.href.split('?')[0];
      if (value === null) {
        url = `${url}?study=${selectedStudy}`;
      } else {
        url = `${url}?study=${selectedStudy}&participant=${value}`;
      }
      window.history.pushState({}, '', url);
    }

    return (
      <Box sx={{ flexGrow: 1, width: '100%', height: '10vh', p: 0, m: 0 }}>
        <AppBar position="static" enableColorOnDark 
          sx={{ p: 1}}
        >
          <Toolbar disableGutters>
            <Box display={{ xs: 'none', md: 'flex' }} marginRight="8px">
              <img src={`${process.env.PUBLIC_URL}/snapi.svg`} alt="YawnLabs Logo" style={{ height: '3rem' }} />
            </Box>
            <Button onClick={dashHome} 
              sx={{ textDecoration: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                  textDecoration: 'none',
                }
               }}
            >
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/dashboard"
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'white',
                  textDecoration: 'none',
                  borderRadius: '10px',
                  backgroundColor: 'transparent', 
                  transition: 'background-color 0.3s ease, color 0.3s ease',
                  '&:hover': {
                    color: theme.palette.text.primary,
                    textDecoration: 'none',
                  }
                }}
              >
                YAWNLABS
              </Typography>
            </Button>

            {selectedStudy ? (
            <>
              <Divider 
                orientation="vertical"
                flexItem
                sx={{marginRight: '0.5rem', marginLeft: '0.5rem'}}
              />

              <Button 
                onClick={() => changeStudy(selectedStudy)}
                sx={{ textDecoration: 'none',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'none',
                  }
                }}
              >
                <Typography
                  variant="subtitle1"
                  noWrap
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'white',
                    textDecoration: 'none',
                    borderRadius: '10px',
                    backgroundColor: 'transparent', 
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                    p: 0,
                    m: 0,
                    '&:hover': {
                      color: theme.palette.text.primary,
                      textDecoration: 'none',
                    }
                  }}
                >
                  {selectedStudy}
                </Typography>
              </Button>
              {studies.length > 1 ? (
                <>
                  <IconButton
                    onClick={handleClickStudies}
                    color="inherit"
                    sx={{p:0, m:0, '&:hover': {
                      color: theme.palette.text.primary,
                      backgroundColor: 'transparent',
                    }}}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                  <Menu
                    id="study-menu"
                    anchorEl={anchorStud}
                    keepMounted
                    open={Boolean(anchorStud)}
                    onClose={handleCloseStudies}
                  >
                    {studies.map((study) => (
                      <MenuItem
                        key={study}
                        onClick={() => {
                          changeStudy(study);
                          handleCloseStudies();
                        }}
                      >
                        {study}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              ) : null}
              
              <Divider 
                orientation="vertical"
                flexItem
                sx={{marginRight: '0.5rem', marginLeft: '0.5rem'}}
              />

              {selectedParticipant ? (
                <>
                  <Button 
                    onClick={() => changeParticipant(selectedParticipant)} 
                    sx={{ textDecoration: 'none',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        textDecoration: 'none',
                      }
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      noWrap
                      sx={{
                        display: { xs: 'none', md: 'flex' },
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'white',
                        textDecoration: 'none',
                        borderRadius: '10px',
                        backgroundColor: 'transparent', 
                        transition: 'background-color 0.3s ease, color 0.3s ease',
                        p: 0,
                        m: 0,
                        '&:hover': {
                          color: theme.palette.text.primary,
                          textDecoration: 'none',
                        }
                      }}
                    >
                      {selectedParticipant}
                    </Typography>
                  </Button>

                  <Divider 
                    orientation="vertical"
                    flexItem
                    sx={{marginRight: '0.5rem', marginLeft: '0.5rem'}}
                  />

                  { selectedDate ? (
                    <Typography
                      variant="subtitle2"
                      noWrap
                      sx={{
                        display: { xs: 'none', md: 'flex' },
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'white',
                        textDecoration: 'none',
                        borderRadius: '10px',
                        backgroundColor: 'transparent', 
                        transition: 'background-color 0.3s ease, color 0.3s ease',
                        p: 0,
                        m: 0,
                      }}
                    >
                      {`${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`}
                    </Typography>
                  ) : null }
                </>
              ) : (
                <>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    onClick={() => setTabIndex(0)}
                    sx={{
                      borderRadius: '1rem',
                      padding: '0.5rem',
                      margin: '0.2rem',
                      color: tabIndex === 0 ? theme.palette.secondary.main : 'inherit',
                      '&:hover': {
                        color: theme.palette.text.primary,
                        textDecoration: 'none',
                      }
                    }}
                  >
                    <TableChartIcon />
                  </IconButton>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleClick}
                    sx={{
                      borderRadius: '1rem',
                      padding: '0.5rem',
                      margin: '0.2rem',
                      color: tabIndex !== 0 ? theme.palette.secondary.main : 'inherit',
                      '&:hover': {
                        color: theme.palette.text.primary,
                        textDecoration: 'none',
                      }
                    }}
                  >
                    <InsertChartIcon />
                  </IconButton>
                </>
              )}
            </>
            ) : null}

            <Box sx={{ width: 'auto', flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                  width: 'auto',
                  gap: '0.5rem',
                }}
              >
              </Menu>
            </Box>
            {isMobile ? 
              <Typography
                variant="h6"
                noWrap
                sx={{
                  padding: '2px',
                  mr: 2,
                  display: { xs: 'flex', md: 'none' },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'white',
                  textDecoration: 'none',
                  borderRadius: '10px',
                  backgroundColor: 'transparent',
                }}
              >
                {selectedStudy} | 
              </Typography>
              : null }
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                padding: '2px',
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'white',
                textDecoration: 'none',
                borderRadius: '10px',
                backgroundColor: 'transparent', 
                transition: 'background-color 0.3s ease, color 0.3s ease',
                '&:hover': {
                  backgroundColor: alpha(theme.palette.text.primary, 0.8),
                  color: theme.palette.primary.main,
                  textDecoration: 'none',
                }
              }}
            >
              YAWNLABS
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}/>
            
            {participants.length > 0 ? (
              <Search
                sx={{
                  borderRadius: '1.5rem',
                }}
              >
                <Autocomplete
                  disablePortal
                  sx={{
                    display: 'inline-block',
                    width: '20vw', m: 1,
                    paddingLeft: 1, paddingRight: 1,
                    color: theme.palette.primary.contrastText,
                    '& input': {
                      color: (theme) =>
                        theme.palette.primary.contrastText,
                    },
                    '& label': {
                      color: (theme) =>
                        theme.palette.primary.contrastText,
                    },
                    '& label.Mui-focused': {
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                  options={participants.map((participant) => participant)}
                  renderInput={(params) => (
                    <TextField 
                      {...params} focused
                      label="Participants"
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ color: theme.palette.primary.contrastText, mr: 1, my: 0.5 }} />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                  onChange={handleAutocompleteChange}
                />
              </Search>
            ) : null}
            

            <Divider 
              orientation="vertical"
              flexItem
              sx={{marginRight: '0.5rem', marginLeft: '0.5rem'}}
            />

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <AccountBoxIcon sx={{ 
                    color: 'white',
                    height: '3rem', 
                    width: '3rem' }} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {accessToken ? (
                  settings.map((setting) => (
                    <Link to = {`/${setting.toLowerCase()}`} style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
                      <MenuItem key={setting} onClick={handleCloseUserMenu}>
                        <Typography textAlign="center">{setting}</Typography>
                      </MenuItem>
                    </Link>
                  ))
                  ) : (
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Link to = '/login' style={{ textDecoration: 'none' }}>
                        <Typography textAlign="center">Login</Typography>
                      </Link>
                    </MenuItem>
                  )}
              </Menu>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={open}
                onClose={handleClose}
              >
                {menuItems.map((menuItem, index) => (
                  <MenuItem key={menuItem.label} onClick={() => handleMenuItemClick(menuItem.value)}>
                    <Typography textAlign="center">{menuItem.label}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    );
}

export default DashNavBar;