import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from '@mui/icons-material/Refresh';
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import DownloadIcon from '@mui/icons-material/Download';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { withStyles } from "tss-react/mui";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import { RefreshPx, QueueFetchStudyData, ExportPxs, deleteParticipant } from "../../functions/snapi";

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
};

class CustomToolbarSelect extends React.Component {
  state = {
    isDeleteDialogOpen: false,
  };

  handleClickDeleteSelected = () => {
    this.setState({ isDeleteDialogOpen: true });
  };

  handleConfirmDelete = () => {
    // Perform deletion logic here
    this.setState({ isDeleteDialogOpen: false });
    
    const { 
      selectedRows, displayData, accessToken
    } = this.props;

    const rows = selectedRows.data.map(row => displayData[row.dataIndex]);
    const pxs = rows.map(row => row.data[0]);
    console.log(pxs);

    for (const px of pxs) {
      deleteParticipant({ accessToken, participantID: px });
    }

  };

  handleCancelDelete = () => {
    this.setState({ isDeleteDialogOpen: false });
  };

  handleClickInverseSelection = () => {
    const nextSelectedRows = this.props.displayData.reduce((nextSelectedRows, _, index) => {
      if (!this.props.selectedRows.data.find(selectedRow => selectedRow.index === index)) {
        nextSelectedRows.push(index);
      }

      return nextSelectedRows;
    }, []);

    this.props.setSelectedRows(nextSelectedRows);
  };

  handleClickDeselectAll = () => {
    this.props.setSelectedRows([]);
  };

  handleClickRefreshSelected = () => {
    const { 
        selectedRows, displayData,
        accessToken, queue, studyData, selectedStudy,
        setStudyData, setStudyLoading
    } = this.props;

    const rows = selectedRows.data.map(row => displayData[row.dataIndex]);

    rows.forEach(row => {
        console.log(studyData.sources)
        studyData.sources.forEach(source => {
            RefreshPx({ accessToken, pxID: row.data[0], source, queue });
        });
    });

    QueueFetchStudyData({ accessToken, selectedStudy, setStudyData, setStudyLoading, queue });
  };

  handleClickExportSelected = () => {
    const { 
        selectedRows, displayData,
        accessToken, queue, studyData
    } = this.props;

    const rows = selectedRows.data.map(row => displayData[row.dataIndex]);
    const pxs = rows.map(row => row.data[0]);
    const sources = studyData.sources;

    for (const source of sources) {
        ExportPxs({ accessToken, pxs, source, queue });
    }
  };

  render() {
    const { classes, selectedRows } = this.props;
    const { isDeleteDialogOpen } = this.state;
    const nSelected = selectedRows.data.length;

    return (
      <div>
        <div className={classes.iconContainer}>
          <Tooltip title={"Deselect ALL"}>
            <IconButton className={classes.iconButton} onClick={this.handleClickDeselectAll}>
              <IndeterminateCheckBoxIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Inverse selection"}>
            <IconButton className={classes.iconButton} onClick={this.handleClickInverseSelection}>
              <ImportExportIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Refresh selected"}>
            <IconButton className={classes.iconButton} onClick={this.handleClickRefreshSelected}>
            <RefreshIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Export selected"}>
            <IconButton className={classes.iconButton} onClick={this.handleClickExportSelected}>
              <DownloadIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Delete selected"}>
            {nSelected === 1 ? (
              <IconButton className={classes.iconButton} onClick={this.handleClickDeleteSelected}>
                <PersonRemoveIcon className={classes.icon} />
              </IconButton>
            ) : (
              <IconButton className={classes.iconButton} onClick={this.handleClickDeleteSelected}>
                <GroupRemoveIcon className={classes.icon} />
              </IconButton>
            )}
          </Tooltip>
        </div>
        <Dialog open={isDeleteDialogOpen} onClose={this.handleCancelDelete}>
          <DialogTitle>Delete Participants</DialogTitle>
          <DialogContent>
            {`Are you sure you wish to delete ${nSelected} participant(s) and all associated data? This will send a request to the server admin to delete the participant(s) from the database.`}
            <br /><br />
            {`This action cannot be undone, and data backup is recommended.`}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancelDelete} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleConfirmDelete} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(CustomToolbarSelect, defaultToolbarSelectStyles, { name: "CustomToolbarSelect" });