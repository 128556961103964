import React, { useState, useEffect } from 'react';
import { Box, Button, Card, CardContent, Divider, Modal, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BedtimeRoundedIcon from '@mui/icons-material/BedtimeRounded';
import MonitorWeightRoundedIcon from '@mui/icons-material/MonitorWeightRounded';
import StraightenRoundedIcon from '@mui/icons-material/StraightenRounded';
import MonitorHeartRoundedIcon from '@mui/icons-material/MonitorHeartRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import { useParticipant } from '../context/ParticipantContext';
import { useDash } from "../context/DashContext";
import FormPdf from './dialogues_forms/pdf';
import { min, set } from 'date-fns';

interface ParticipantDataProps {
  className?: string;
}

interface ArrowIconProps {
    isCardExpanded: boolean;
}

const ParticipantCard: React.FC<ParticipantDataProps> = ({
  className
}) => {
    const theme = useTheme();
    
    const { 
        filteredSleepData, filteredMeasData, participantData,
        setFilteredSleepData, setFilteredMeasData, setFilteredEventData, 
        participantSleepData, participantMeasData, participantEventData,
        startDate, endDate, filteredEventData, setStartDate, setEndDate,
        setSummaryData, setEarliestDate, setLatestDate, earliestDate, latestDate,
        summaryData
     } = useParticipant();
    const { isCardExpanded, setIsCardExpanded } = useDash();

    useEffect(() => {
        const filtered = participantSleepData.filter(item => {
            const itemDate = new Date(item.w_enddate);
            const start = startDate ? new Date(startDate) : null;
            const end = endDate ? new Date(endDate) : null;
            return (start ? itemDate >= start : true) && (end ? itemDate <= end : true);
        });
        setFilteredSleepData(filtered);
    }, [startDate, endDate, participantSleepData, setFilteredSleepData]);

    useEffect(() => {
        const filtered = participantMeasData.filter(item => {
            const itemDate = new Date(item.wo_date);
            const start = startDate ? new Date(startDate) : null;
            const end = endDate ? new Date(endDate) : null;
            return (start ? itemDate >= start : true) && (end ? itemDate <= end : true);
        });
        setFilteredMeasData(filtered);
    }, [startDate, endDate, participantMeasData, setFilteredMeasData]);

    useEffect(() => {
        const filtered = participantEventData.filter(item => {
            const itemDate = new Date(item.wo_date);
            const start = startDate ? new Date(startDate) : null;
            const end = endDate ? new Date(endDate) : null;
            return (start ? itemDate >= start : true) && (end ? itemDate <= end : true);
        });
        setFilteredEventData(filtered);
    }, [startDate, endDate, participantEventData, setFilteredEventData]);

    useEffect(() => {
        let sleep_onsets = filteredSleepData.map((item: any) => {
            // Remove the timezone from the date string
            const dateString = item.w_startdate.replace(' GMT', '');
            return new Date(dateString);
        });
    
        let sleep_offsets = filteredSleepData.map((item: any) => {
            // Remove the timezone from the date string
            const dateString = item.w_enddate.replace(' GMT', '');
            return new Date(dateString);
        });

        // Sort the arrays, given they are dates
        sleep_onsets.sort((a, b) => a.getTime() - b.getTime());
        sleep_offsets.sort((a, b) => a.getTime() - b.getTime());
                
        // Calculate the mean time in bed and mean time awake
        let meanInBed = 0;
        let meanAwake = 0;
        
        for (let i = 0; i < sleep_onsets.length; i++) {
            if (sleep_onsets[i].getHours() < 12) {
                meanInBed += sleep_onsets[i].getHours() + 24 + sleep_onsets[i].getMinutes() / 60;
            } else {
                meanInBed += sleep_onsets[i].getHours() + sleep_onsets[i].getMinutes() / 60;
            }
            meanAwake += sleep_offsets[i].getHours() + sleep_offsets[i].getMinutes() / 60;
        }

        meanInBed /= sleep_onsets.length;
        meanAwake /= sleep_offsets.length;

        // If either is >24, subtract 24 to get the correct time
        if (meanInBed > 24) {
            meanInBed -= 24;
        }
        if (meanAwake > 24) {
            meanAwake -= 24;
        }

        // Convert the hours to a string in the format "hh:mm" in 24h time
        const inBed = `${Math.floor(meanInBed).toString().padStart(2, '0')}:${Math.round((meanInBed % 1) * 60).toString().padStart(2, '0')}`;
        const outBed = `${Math.floor(meanAwake).toString().padStart(2, '0')}:${Math.round((meanAwake % 1) * 60).toString().padStart(2, '0')}`;

        // Calculate the mean total sleep time
        const meanTST = filteredSleepData.reduce(
            (total, data) => total + data.w_total_sleep_time, 0
        ) / filteredSleepData.length;
        const stdTST = Math.sqrt(filteredSleepData.reduce(
            (total, data) => total + Math.pow(data.w_total_sleep_time - meanTST, 2), 0
        ) / filteredSleepData.length);

        // Calculate the mean sleep efficiency
        const meanSE = filteredSleepData.reduce(
            (total, data) => total + data.w_sleep_efficiency, 0
        ) / filteredSleepData.length;
        const stdSE = Math.sqrt(filteredSleepData.reduce(
            (total, data) => total + Math.pow(data.w_sleep_efficiency - meanSE, 2), 0
        ) / filteredSleepData.length);

        // Calculate the mean time in bed
        const meanTIB = filteredSleepData.reduce(
            (total, data) => total + data.w_total_timeinbed, 0
        ) / filteredSleepData.length;
        const stdTIB = Math.sqrt(filteredSleepData.reduce(
            (total, data) => total + Math.pow(data.w_total_timeinbed - meanTIB, 2), 0
        ) / filteredSleepData.length);

        // Calculate the mean weight
        const weightData = filteredMeasData.filter(data => data['Weight (kg)'] !== undefined && data['Weight (kg)'] !== null);
        const heightData = filteredMeasData.filter(data => data['Height (meter)'] !== undefined && data['Height (meter)'] !== null);
        const meanWeight = weightData.reduce(
            (total, data) => total + data['Weight (kg)'], 0) / weightData.length;
        const meanHeight = filteredMeasData.reduce(
            (total, data) => total + data['Height (meter)'], 0) / heightData.length;

        const meanBMI = meanWeight / (meanHeight * meanHeight);

        const bmiCategory = isNaN(meanBMI)? '' : meanBMI < 18.5 ? 'Underweight' : meanBMI < 24.9 ? 'Normal weight' : meanBMI < 29.9 ? 'Overweight' : 'Obese';
        
        const meanSOL = parseFloat((filteredSleepData.reduce(
            (total, data) => total + data.w_sleep_latency, 0
        ) / filteredSleepData.length).toFixed(2));
        const stdSOL = Math.sqrt(filteredSleepData.reduce(
            (total, data) => total + Math.pow(data.w_sleep_latency - meanSOL, 2), 0
        ) / filteredSleepData.length);

        const meanWASO = parseFloat((filteredSleepData.reduce(
            (total, data) => total + data.w_waso, 0
        ) / filteredSleepData.length).toFixed(2));
        const stdWASO = Math.sqrt(filteredSleepData.reduce(
            (total, data) => total + Math.pow(data.w_waso - meanWASO, 2), 0
        ) / filteredSleepData.length);

        const meanAHI = filteredSleepData.reduce(
            (total, data) => total + data.w_apnea_hypopnea_index, 0) / filteredSleepData.length;
        const stdAHI = Math.sqrt(filteredSleepData.reduce(
            (total, data) => total + Math.pow(data.w_apnea_hypopnea_index - meanAHI, 2), 0
        ) / filteredSleepData.length);

        const sbpData = filteredMeasData.filter(data => data['SBP (mmHg)'] !== undefined && data['SBP (mmHg)'] !== null);
        const meanSBP = sbpData.reduce(
            (total, data) => total + data['SBP (mmHg)'], 0
        ) / sbpData.length;
        const stdSBP = Math.sqrt(sbpData.reduce(
            (total, data) => total + Math.pow(data['SBP (mmHg)'] - meanSBP, 2), 0
        ) / sbpData.length);

        const dbpData = filteredMeasData.filter(data => data['DBP (mmHg)'] !== undefined && data['DBP (mmHg)'] !== null);
        const meanDBP = dbpData.reduce(
            (total, data) => total + data['DBP (mmHg)'], 0
        ) / sbpData.length;
        const stdDBP = Math.sqrt(dbpData.reduce(
            (total, data) => total + Math.pow(data['DBP (mmHg)'] - meanDBP, 2), 0
        ) / dbpData.length);

        const meanHR = filteredSleepData.reduce(
            (total, data) => total + data.w_hr_average, 0
        ) / filteredSleepData.length;
        const minHR = filteredSleepData.reduce(
            (total, data) => total + data.w_hr_min, 0
        ) / filteredSleepData.length;
        const maxHR = filteredSleepData.reduce(
            (total, data) => total + data.w_hr_max, 0
        ) / filteredSleepData.length;

        const meanRR = filteredSleepData.reduce(
            (total, data) => total + data.w_rr_average, 0
        ) / filteredSleepData.length;
        const minRR = filteredSleepData.reduce(
            (total, data) => total + data.w_rr_min, 0
        ) / filteredSleepData.length;
        const maxRR = filteredSleepData.reduce(
            (total, data) => total + data.w_rr_max, 0
        ) / filteredSleepData.length;

        // Set toSleep and toWake as the mean time in bed and mean time awake + or - the meanSOL and meanWASO
        let meanToSleep = meanInBed + meanSOL/60;
        let meanToWake = meanAwake - meanWASO/60;

        // If either is >24, subtract 24 to get the correct time
        if (meanToSleep > 24) {
            meanToSleep -= 24;
        }
        if (meanToWake > 24) {
            meanToWake -= 24;
        }

        // Convert the hours to a string in the format "hh:mm" in 24h time
        const toSleep = `${Math.floor(meanToSleep).toString().padStart(2, '0')}:${Math.round((meanToSleep % 1) * 60).toString().padStart(2, '0')}`;
        const toWake = `${Math.floor(meanToWake).toString().padStart(2, '0')}:${Math.round((meanToWake % 1) * 60).toString().padStart(2, '0')}`;
        const participantID = participantData?.lab_id;

        setSummaryData({
            participantID, earliestDate, latestDate,
            meanWeight, meanHeight, meanBMI, bmiCategory,
            meanTST, stdTST, meanTIB, stdTIB, meanSE, stdSE,
            meanSOL, stdSOL, meanWASO, stdWASO,
            meanInBed: inBed, meanAwake: outBed,
            meanToSleep: toSleep, meanToWake: toWake,
            meanAHI, stdAHI,
            meanSBP, stdSBP, meanDBP, stdDBP,
            meanHR, minHR, maxHR,
            meanRR, minRR, maxRR
        });

    }, [filteredSleepData, filteredMeasData, participantData]);

    const ArrowIcon: React.FC<ArrowIconProps> = ({ isCardExpanded }) => {
      return (
        <div  
            className={className}
            onClick={() => {
                if (isCardExpanded) {
                    setIsCardExpanded(false);
                } else {
                    setIsCardExpanded(true);
                }
            }} 
            style={{ 
                cursor: 'pointer',
                height: 'fit-content',
                position: 'absolute',
                top: 0,
                right: 5,
                padding: '0px',
                paddingTop: '0.5rem',
                display: 'inline-flex',
                alignItems: 'top',
                color: theme.palette.secondary.main,
                zIndex: 1,
            }}
        >
            <KeyboardDoubleArrowLeftIcon 
                style={{ 
                    transform: isCardExpanded ? 'rotate(180deg)' : 'none', 
                    transition: 'transform 0.3s ease-in-out' 
                }} 
            />
        </div>
      );
    };

    return (
      <div style={{ 
        position: 'relative', width: isCardExpanded ? '0%' : '100%',
        marginRight: '2%'
      }}>
        <ArrowIcon isCardExpanded={isCardExpanded}/>
        {!isCardExpanded && (
          <>
            <Card 
                variant = "outlined"
                sx = {{
                    width: '100%',
                    textAlign: 'left',
                    height: '100%',
                    overflowY: 'scroll'
                }}
            >
              <CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingBottom: '1%',
                        paddingTop: '1%',
                    }}
                >
                  <Typography variant="h5" sx={{ paddingBottom: '1%', letterSpacing: '0.12em' }}>
                      {!isCardExpanded && <strong>{participantData?.lab_id}</strong>}
                  </Typography>
                  <FormPdf/>
                </Box>
                  <Divider />
                      <Table
                        id={`summaryTable_${participantData?.lab_id}`}
                      >
                          <TableBody>
                                <TableRow sx={{ '& .MuiTableCell-root': { border: 0 } }}>
                                  <TableCell colSpan={2}>
                                      <MonitorWeightRoundedIcon sx={{ marginRight: '0.5rem', verticalAlign: 'middle', color: theme.palette.primary.light }} />
                                  </TableCell>
                                  <TableCell colSpan={2} sx={{
                                    textAlign: 'center',
                                    margin: '0 auto',
                                    padding: '0 auto',
                                  }}>
                                      <Typography variant="body2" sx={{ letterSpacing: '0.1em', textDecoration: 'underline' }}>
                                          <strong>Demographics</strong>
                                      </Typography>
                                  </TableCell>
                              </TableRow>
                              <TableRow sx={{ '& .MuiTableCell-root': { border: 0 } }}>
                                  <TableCell colSpan={2}>
                                      <Typography variant="body2" sx={{ letterSpacing: '0.1em'}}>
                                        <strong>Weight</strong> (kg)    
                                      </Typography>
                                  </TableCell>
                                  <TableCell colSpan={2} sx={{textAlign: 'center'}}>
                                      {summaryData?.meanWeight?.toFixed(1)}
                                  </TableCell>
                                  <TableCell/>
                              </TableRow>
                              <TableRow  sx={{ '& .MuiTableCell-root': { border: 0 } }}>
                                  <TableCell colSpan={2}>
                                      <Typography variant="body2" sx={{ letterSpacing: '0.1em'}}>
                                          <strong>Height</strong> (m)
                                      </Typography>
                                  </TableCell>
                                  <TableCell colSpan={2} sx={{textAlign: 'center'}}>
                                      {summaryData?.meanHeight?.toFixed(1)}
                                  </TableCell>
                                  <TableCell/>
                              </TableRow>
                              <TableRow>
                                  <TableCell colSpan={2}>
                                      <Typography variant="body2" sx={{ letterSpacing: '0.1em'}}>
                                          <strong>Body Mass Index</strong> (kg/m<sup>2</sup>)
                                      </Typography>
                                  </TableCell>
                                  <TableCell colSpan={2} sx={{textAlign: 'center'}}>
                                      {summaryData?.meanBMI?.toFixed(1)}<br/>
                                      {summaryData?.bmiCategory}
                                  </TableCell>
                                  <TableCell/>
                              </TableRow>
                              <TableRow sx={{ '& .MuiTableCell-root': { border: 0 } }}>
                                  <TableCell colSpan={2}>
                                      <BedtimeRoundedIcon sx={{ marginRight: '0.5rem', verticalAlign: 'middle', color: theme.palette.primary.light }} />
                                  </TableCell>
                                  <TableCell colSpan={2} sx={{
                                    textAlign: 'center',
                                    margin: '0 auto',
                                    padding: '0 auto',
                                  }}>
                                      <Typography variant="body2" sx={{ letterSpacing: '0.1em', textDecoration: 'underline' }}>
                                          <strong>Sleep</strong>
                                      </Typography>
                                  </TableCell>
                              </TableRow>
                              <TableRow sx={{ '& .MuiTableCell-root': { border: 0 } }}>
                                  <TableCell colSpan={2}>
                                      <Typography variant="body2" sx={{ letterSpacing: '0.1em'}}>
                                          <strong>Duration</strong><br/>
                                          (Hours asleep vs. in-bed)
                                      </Typography>
                                  </TableCell>
                                  <TableCell sx={{textAlign: 'center'}}>
                                        {summaryData?.meanTST?.toFixed(1)} ± {summaryData?.stdTST?.toFixed(1)}
                                  </TableCell>
                                  <TableCell sx={{textAlign: 'center'}}>
                                        {summaryData?.meanTIB?.toFixed(1)} ± {summaryData?.stdTIB?.toFixed(1)}
                                  </TableCell>
                                  <TableCell></TableCell>
                              </TableRow>
                              <TableRow sx={{ '& .MuiTableCell-root': { border: 0 } }}>
                                  <TableCell colSpan={2}>
                                      <Typography variant="body2" sx={{ letterSpacing: '0.1em'}}>
                                          <strong>Bed Timing</strong><br/>
                                          (In vs. out of bed)
                                      </Typography>
                                  </TableCell>
                                  <TableCell sx={{textAlign: 'center'}}>
                                    {summaryData?.meanInBed}
                                  </TableCell>
                                  <TableCell sx={{textAlign: 'center'}}>
                                    {summaryData?.meanAwake}
                                  </TableCell>
                                  <TableCell></TableCell>
                              </TableRow>
                              <TableRow sx={{ '& .MuiTableCell-root': { border: 0 } }}>
                                  <TableCell colSpan={2}>
                                      <Typography variant="body2" sx={{ letterSpacing: '0.1em'}}>
                                          <strong>Sleep Timing</strong><br/>
                                          (Asleep vs. wake)
                                      </Typography>
                                    </TableCell>
                                  <TableCell sx={{textAlign: 'center'}}>
                                    {summaryData?.meanToSleep}
                                  </TableCell>
                                  <TableCell sx={{textAlign: 'center'}}>
                                    {summaryData?.meanToWake}
                                  </TableCell>
                              </TableRow>
                              <TableRow sx={{ '& .MuiTableCell-root': { border: 0 } }}>
                                  <TableCell colSpan={2}>
                                      <Typography variant="body2" sx={{ letterSpacing: '0.1em'}}>
                                          <strong>Sleep Latency</strong><br/>
                                          (Onset vs. offset, minutes)
                                      </Typography>
                                  </TableCell>
                                  <TableCell sx={{textAlign: 'center'}}>
                                    {summaryData?.meanSOL?.toFixed(0)} ± {summaryData?.stdSOL?.toFixed(0)}
                                  </TableCell>
                                  <TableCell sx={{textAlign: 'center'}}>
                                    {summaryData?.meanWASO?.toFixed(0)} ± {summaryData?.stdWASO?.toFixed(0)}
                                  </TableCell>
                                  <TableCell></TableCell>
                              </TableRow>
                              <TableRow sx={{ '& .MuiTableCell-root': { border: 0 } }}>
                                  <TableCell colSpan={2}>
                                      <Typography variant="body2" sx={{ letterSpacing: '0.1em'}}>
                                          <strong>Sleep Efficiency</strong><br/>
                                            (Percentage asleep vs. in-bed)
                                      </Typography>
                                  </TableCell>
                                  <TableCell colSpan={2} sx={{textAlign: 'center'}}>
                                      {summaryData?.meanSE?.toFixed(0)} ± {summaryData?.stdSE?.toFixed(0)}%
                                  </TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell colSpan={2}>
                                      <Typography variant="body2" sx={{ letterSpacing: '0.1em'}}>
                                          <strong>Apnea Hypopnea Index</strong><br/>
                                            (Events per hour)
                                      </Typography>
                                  </TableCell>
                                  <TableCell colSpan={2} sx={{textAlign: 'center'}}>
                                      {summaryData?.meanAHI?.toFixed(1)} ± {summaryData?.stdAHI?.toFixed(1)}
                                  </TableCell>
                                  <TableCell/>
                              </TableRow>
                              <TableRow sx={{ '& .MuiTableCell-root': { border: 0 } }}>
                                  <TableCell colSpan={2}>
                                      <MonitorHeartRoundedIcon sx={{ marginRight: '0.5rem', verticalAlign: 'middle', color: theme.palette.primary.light }} />
                                  </TableCell>
                                  <TableCell colSpan={2} sx={{
                                    textAlign: 'center',
                                    margin: '0 auto',
                                    padding: '0 auto',
                                  }}>
                                      <Typography variant="body2" sx={{ letterSpacing: '0.1em', textDecoration: 'underline' }}>
                                          <strong>Cardio-Respiratory</strong>
                                      </Typography>
                                  </TableCell>
                              </TableRow>
                              <TableRow sx={{ '& .MuiTableCell-root': { border: 0, paddingBottom: '0px' } }}>
                                  <TableCell colSpan={2}>
                                      <Typography variant="body2" sx={{ letterSpacing: '0.1em'}}>
                                          <strong>Blood Pressure</strong><br/>
                                          (Systolic vs. Diastolic mmHg)
                                      </Typography>
                                  </TableCell>
                                  <TableCell sx={{textAlign: 'center'}}>
                                      {summaryData?.meanSBP?.toFixed(0) ?? 'N/A'}
                                  </TableCell>
                                  <TableCell sx={{textAlign: 'center'}}>
                                      {summaryData?.meanDBP?.toFixed(0) ?? 'N/A'}
                                  </TableCell>
                              </TableRow>
                              <TableRow sx={{ '& .MuiTableCell-root': { border: 0 } }}>
                                  <TableCell colSpan={2}>
                                      <Typography variant="body2" sx={{ letterSpacing: '0.1em'}}>
                                          <strong>Heart Rate</strong><br/>
                                            (Overnight beats per minute)
                                      </Typography>
                                  </TableCell>
                                  <TableCell colSpan={2} sx={{textAlign: 'center'}}>
                                      {summaryData?.meanHR?.toFixed(0)}<br/>
                                      [{summaryData?.minHR?.toFixed(0)} - {summaryData?.maxHR?.toFixed(0)}]
                                  </TableCell>
                                  <TableCell/>
                              </TableRow>
                              <TableRow>
                                  <TableCell colSpan={2}>
                                      <Typography variant="body2" sx={{ letterSpacing: '0.1em'}}>
                                          <strong>Respiratory Rate</strong><br/>
                                            (Overnight breaths per minute)
                                      </Typography>
                                  </TableCell>
                                  <TableCell colSpan={2} sx={{textAlign: 'center'}}>
                                      {summaryData?.meanRR?.toFixed(0)}<br/>
                                      [{summaryData?.minRR?.toFixed(0)} - {summaryData?.maxRR?.toFixed(0)}]
                                  </TableCell>
                                  <TableCell/>
                              </TableRow>
                          </TableBody>
                      </Table>
              </CardContent>
            </Card>
          </>
        )}
        </div>
    );
};

export default ParticipantCard;