import { useTheme, Box, Typography, useMediaQuery, Card, CardContent, CardMedia, Grid } from '@mui/material';

import useDocumentTitle from '../functions/useDocumentTitle';
import ResponsiveAppBar from '../components/NavBar';

const cardData = [
  { 
    image: `${process.env.PUBLIC_URL}/feat/charts.png`,
    title: 'Long Term Trends',
    text: 'View long term trends in your data to understand how your participants are progressing over time.',
  },
  {
    image: `${process.env.PUBLIC_URL}/feat/manage.png`,
    title: 'Manage and Export',
    text: 'Easily manage your participants and export data for further analysis.',
  },
  // {
  //   image: `${process.env.PUBLIC_URL}/feat/notifications.png`,
  //   title: 'Notifications',
  //   text: 'Receive notifications when your participants complete tasks or when new data is available.',
  // },
  // {
  //   image: `${process.env.PUBLIC_URL}/feat/secure.png`,
  //   title: 'Secure',
  //   text: 'We take security seriously. Your data is encrypted and stored securely.',
  // }
];
const About: React.FC = () => {
  useDocumentTitle('YawnLabs | About');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        marginTop: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: theme.spacing(2),
      }}
    >
      <ResponsiveAppBar currentPage='About' />
      <Box sx={{ height: theme.spacing(2) }} />
      <Box 
        sx={{ 
          display: 'flex', flexDirection: 'column', alignItems: 'center',
          width: 'auto', overflow: 'scroll', 
          maxHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`, 
          paddingTop: theme.spacing(2), 
          marginBottom: theme.spacing(2),
          marginLeft: '2vw',
          marginRight: '2vw',
          justifyContent: 'center',
        }}
      >
        <Grid container spacing={2} justifyContent="center" overflow='scroll'
          sx={{
            width: isMobile ? '100%' : '60%'
          }}
        >
          {cardData.map((card, index) => (
            <Grid item 
              xs={12} sm={12} md={12} key={index}
              sx={{ 
                paddingBottom: theme.spacing(2),

               }}
            >
              <Card sx={{ 
                display: 'flex', 
                flexDirection: isMobile ? 'column' : (index % 2 === 0 ? 'row' : 'row-reverse'), 
                height: isMobile ? 'calc(100vh - 4rem)' : '40vh',
                width: isMobile ? '90%' : 'auto',
                margin: isMobile ? 'auto' : '1vw',
                marginBottom: theme.spacing(4),
                alignItems: 'center',
              }}>
                <CardMedia
                  component="img"
                  sx={{ 
                    justifySelf: (index % 2 === 0 ? 'flex-start' : 'flex-end'),
                    height: isMobile ? 'calc(100vh - 4rem)' : '40vh',
                    width: isMobile ? '100%' : '60%',
                    maskImage: !isMobile ? (index % 2 === 0 ? 'linear-gradient(to left, transparent, black 75%)' : 'linear-gradient(to right, transparent, black 75%)') : 'linear-gradient(to top, transparent, black 75%)',
                  }}
                  image={card.image}
                  alt="Card image"
                />
                <CardContent 
                sx={{
                  width: isMobile ? '100%' : '30%',
                  padding: '5%'
                }}
                >
                  <Typography variant="h6" sx={{padding: '5%', paddingBottom: '10%'}}>{card.title}</Typography>
                  <Typography variant="body2" sx={{padding: '5%', paddingBottom: '10%' }}>{card.text}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default About;