import React, { useState } from "react";
import { useDash } from "./DashContext";

// Create a context
const QueueContext = React.createContext(null);

// Create a provider component
export const QueueProvider = ({ children }) => {
  const [pendingTasks, setPendingTasks] = useState(0);
  const [completedTasks, setCompletedTasks] = useState(0);
  const [isDrained, setIsDrained] = useState(false);
  const queue = React.useMemo(() => new PromiseQueue((pending, completed) => {
    setPendingTasks(pending);
    setCompletedTasks(completed);
    setIsDrained(pending === 0);
  }), [setPendingTasks, setCompletedTasks, setIsDrained]);

  return (
    <QueueContext.Provider value={{queue, pendingTasks, completedTasks, isDrained}}>
      {children}
    </QueueContext.Provider>
  );
};

// Create a hook to use the queue
export const useQueue = () => {
  const context = React.useContext(QueueContext);
  if (context === undefined) {
    throw new Error('useQueue must be used within a QueueProvider');
  }
  return context;
};

class PromiseQueue {
    private _queue: Array<() => Promise<any>>;
    private _pending: boolean;
    private _completed: number;
    private _callback: (pending: number, completed: number) => void;


    constructor(callback: (pending: number, completed: number) => void) {
        this._queue = [];
        this._pending = false;
        this._completed = 0;
        this._callback = callback;
    }

    add(task: () => Promise<any>) {
        this._queue.push(task);
        this._callback(this._queue.length, this._completed);
        this._run();
    }

    async _run() {
        if (this._pending || this._queue.length === 0) {
            if (this._queue.length === 0) {
                this._completed = 0;
                this._callback(this._queue.length, this._completed);
            }
            return;
        }
        this._pending = true;
        await this._queue.shift()();
        this._pending = false;
        this._completed++;
        this._callback(this._queue.length, this._completed);
        this._run();
    }
}