import { useTheme, Box, Button, Typography, useMediaQuery } from '@mui/material';

import useDocumentTitle from '../functions/useDocumentTitle';
import ResponsiveAppBar from '../components/NavBar';

const Pricing: React.FC = () => {
  useDocumentTitle('YawnLabs | Pricing');
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const handleContactUs = () => {
    const subject = encodeURIComponent("YawnLabs New Inquiry");
    const body = encodeURIComponent(
      "Hello YawnLabs,\n\n" + 
      "I am interested in learning more about your pricing and how I can get started.\n\n" +
      "I intend to recruit up to # participants over # months/years.\n\n"
    );
    window.location.href = `mailto:support@yawnlabs.app?subject=${subject}&body=${body}`;
  };

  return (
    <Box
      sx={{
        marginTop: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <ResponsiveAppBar currentPage='Pricing' />
      <Box sx={{ height: theme.spacing(2) }} />
      <Box sx={{ 
        paddingTop: theme.spacing(2),
        width: matches ? '30%' : '100%',
      }}>
        <Box sx={{ height: theme.spacing(2) }} />
        <Typography variant="body2">
          YawnLabs pricing is custom for each study, typically based on the number of 
          participants you intend to recruit and the duration of your study.
        </Typography>

        <Box sx={{ height: theme.spacing(1) }} />
        <Typography variant="body2">
          If you wish to learn more about our pricing please contact us
          using the button below, or by emailing us at support@yawnlabs.app, 
          and include some details about your needs.
        </Typography>

        <Box sx={{ height: theme.spacing(4) }} />
        <Button variant="contained" color="primary" onClick={handleContactUs}>
          Contact Us
        </Button>
      </Box>
    </Box>
  );
};

export default Pricing;