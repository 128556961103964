import React, { createContext, useState, useContext } from 'react';

interface ParticipantData {
  devices: null;
  email: string;
  lab_id: string;
  last_sync: string;
  meas_data: {
      bp_counts: Array<number>;
      weight_counts: Array<number>;
      mean_sbp: number;
      mean_dbp: number;
      mean_weight: number;
      mean_height: number;
      last_bp: string;
      last_weight: string;
      last_height: string;
  };
  sleep_data: {
      mean_tst: number;
      mean_tib: number;
      mean_se: number;
      mean_ahi: number;
      num_sleeps: number;
      mean_hr?: number;
      mean_rr?: number;
      mean_inbed?: string;
      mean_awake?: string;
  };
  study: string;
}

interface ParticipantContextProps {
  selectedParticipant: string | null;
  setSelectedParticipant: React.Dispatch<React.SetStateAction<string | null>>;
  participantData: ParticipantData | null;
  setParticipantData: React.Dispatch<React.SetStateAction<ParticipantData | null>>;
  participantSleepData: any[];
  setParticipantSleepData: React.Dispatch<React.SetStateAction<any[]>>;
  participantMeasData: any[];
  setParticipantMeasData: React.Dispatch<React.SetStateAction<any[]>>;
  participantEventData: any[];
  setParticipantEventData: React.Dispatch<React.SetStateAction<any[]>>;
  loadingParticipantData: boolean;
  setLoadingParticipantData: React.Dispatch<React.SetStateAction<boolean>>;
  startDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  endDate: Date;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
  filteredSleepData: any[];
  setFilteredSleepData: React.Dispatch<React.SetStateAction<any[]>>;
  filteredMeasData: any[];
  setFilteredMeasData: React.Dispatch<React.SetStateAction<any[]>>;
  filteredEventData: any[];
  setFilteredEventData: React.Dispatch<React.SetStateAction<any[]>>;
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
  dateLoading: boolean;
  setDateLoading: React.Dispatch<React.SetStateAction<boolean>>;
  summaryData: any;
  setSummaryData: React.Dispatch<React.SetStateAction<any>>;
  earliestDate?: Date;
  latestDate?: Date;
  setEarliestDate?: React.Dispatch<React.SetStateAction<Date>>;
  setLatestDate?: React.Dispatch<React.SetStateAction<Date>>;
}

export const ParticipantContext = createContext<ParticipantContextProps | undefined>(undefined);

export const ParticipantProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [selectedParticipant, setSelectedParticipant] = useState<string | null>(null);
  const [participantData, setParticipantData] = useState<ParticipantData | null>(null);
  const [participantSleepData, setParticipantSleepData] = useState<any[]>([]);
  const [participantMeasData, setParticipantMeasData] = useState<any[]>([]);
  const [participantEventData, setParticipantEventData] = useState<any[]>([]);
  const [loadingParticipantData, setLoadingParticipantData] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [filteredSleepData, setFilteredSleepData] = useState<any[]>([]);
  const [filteredMeasData, setFilteredMeasData] = useState<any[]>([]);
  const [filteredEventData, setFilteredEventData] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [dateLoading, setDateLoading] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<any>({});
  const [earliestDate, setEarliestDate] = useState<Date>();
  const [latestDate, setLatestDate] = useState<Date>();

  return (
    <ParticipantContext.Provider
      value={{
        selectedParticipant, setSelectedParticipant,
        participantData, setParticipantData,
        participantSleepData, setParticipantSleepData,
        participantMeasData, setParticipantMeasData,
        participantEventData, setParticipantEventData,
        loadingParticipantData, setLoadingParticipantData,
        startDate, setStartDate,
        endDate, setEndDate,
        filteredSleepData, setFilteredSleepData,
        filteredMeasData, setFilteredMeasData,
        filteredEventData, setFilteredEventData,
        selectedDate, setSelectedDate,
        dateLoading, setDateLoading,
        summaryData, setSummaryData,
        earliestDate, setEarliestDate,
        latestDate, setLatestDate
      }}
    >
      {children}
    </ParticipantContext.Provider>
  );
};

export const useParticipant = () => {
  const context = useContext(ParticipantContext);
  if (context === undefined) {
    throw new Error('useParticipant must be used within a ParticipantProvider');
  }
  return context;
}