import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom'; // Import from react-router-dom
import { Button, TextField, Typography, Container, Box, LinearProgress, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';

import { useAuth } from '../context/AuthContext'; // Adjust the path based on your file structure
import useDocumentTitle from '../functions/useDocumentTitle';
import { fetchDashPreferences } from '../functions/snapi';

interface HistoryState {
  from: {
    pathname: string;
    search: string;
  };
}

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { setAccessToken, setUserSettingsState } = useAuth(); // Use the context
  const history = useHistory(); // For redirection

  const passwordInputRef = useRef<HTMLInputElement>(null);

  useDocumentTitle('YawnLabs | Login');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    passwordInputRef.current?.focus();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setUsername(username.trim());
    setPassword(password.trim());

    const token = btoa(`${username}:${password}`);
    try {
      const response = await axios.post('https://www.snapi.space/api/tokens', {}, {
        headers: {
          'Authorization': `Basic ${token}`
        }
      });

      const accessToken = response.data['access_token'];
      setAccessToken(accessToken); // Store the token using context
      // history.push('/dashboard'); // Redirect to Dashboard
      // redirect to previous page
      
      fetchDashPreferences({ accessToken }).then((data) => {
        if (data) {
          setUserSettingsState(data);
          localStorage.setItem('userSettings', JSON.stringify(data));
        }
      });

      try {
        let from = (history.location.state as HistoryState).from.pathname
        let args = (history.location.state as HistoryState).from.search
        console.log('from:', from, 'args:', args);
        history.push('/dashboard');
      } catch (error) {
        history.push('/dashboard');
      }
      
    } catch (error) {
      console.log(error);
      const description = error.response?.data?.description;
      if (description) {
        setError(description);
      } else {
        setError('An error occurred.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            inputRef={passwordInputRef}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            Sign In
          </Button>
          {loading && <LinearProgress sx={{ width: '100%', my: 1 }} />}
          {error && <Typography 
            className="error" 
            sx={{ color: 'red', textAlign: 'center', mt: 1 }}
            >{error}</Typography>}
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
