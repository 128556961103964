import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { 
    Typography, Box, useTheme, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Button, Grid, Snackbar, CircularProgress, Tabs, Tab, alpha, 
    TextField} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import ChartBox from '../components/charts/ChartBox';
import SideBar from '../components/SideBar';
import DashNavBar from '../components/DashNavBar';
import ParticipantCard from '../components/ParticipantCard';
import { useDash } from '../context/DashContext';
import { useParticipant } from '../context/ParticipantContext';
import { useQueue } from '../context/QueueContext';
import { fetchParticipantData, fetchParticipantEvents, fetchStudies,
  fetchParticipants, fetchStudyData, RefreshPx, QueueFetchStudyData } from '../functions/snapi';
import useDocumentTitle from '../functions/useDocumentTitle';
import CircularProgressWithLabel from '../components/CircularProgressWithLabel';

import StudyDataTable from '../components/dataTables/studyDataTable';
import { StudyDataChartSleep, StudyDataChartBP, StudyDataChartWeight, StudyDataChartBPTrend } from '../components/charts/StudyDataCharts';
import Daily from '../components/daily/daily';

const Dashboard: React.FC = () => {
    useDocumentTitle('YawnLabs | Dashboard');

    const history = useHistory();
    const { 
        accessToken,
        selectedStudy, setSelectedStudy, 
        studyData, setStudyData,
        setParticipants,
        studies, setStudies,
    } = useAuth();
    const { 
        selectedParticipant, setSelectedParticipant,
        selectedDate, setSelectedDate,
        dateLoading, setDateLoading,
        setParticipantData,
        setParticipantSleepData,
        setParticipantMeasData,
        setParticipantEventData,
        loadingParticipantData, setLoadingParticipantData,
    } = useParticipant();

    const { setColumnsList, setMeasColumnsList, setParticipantsLoading, 
      setStudyLoading, studyLoading, tabIndex } = useDash();

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const { pendingTasks, completedTasks } = useQueue();

    useEffect(() => {
      if (pendingTasks > 0) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }, [completedTasks, pendingTasks]);    


    const [searchTerm, setSearchTerm] = useState('');
    const filteredStudies = studies.filter((study) =>
      study.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const progressWheel = (
      <React.Fragment>
        <CircularProgressWithLabel 
          value={(completedTasks / (pendingTasks+completedTasks)) * 100}
        />
      </React.Fragment>
    );

    useEffect(() => {
        fetchStudies({ accessToken, setStudies }).then(result => {
          console.log(result)
          if (result === 201) {
            console.log('Studies fetched');
          } else {
            // Redirect to login page, then back to dashboard
            history.push('/login');
          }
        })
    }, [accessToken, history, setStudies]);
    
    // if there is a 'study' argument in the url, set it as the selected study
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const study = urlParams.get('study');
        const participant = urlParams.get('participant');
        const date = urlParams.get('date');
        if (study && studies.includes(study)) {
            setSelectedStudy(study);
        }
        if (participant) {
            setSelectedParticipant(participant);
        }
        if (date) {
            setSelectedDate(new Date(date));
        }
    }, [studies, setSelectedStudy, setSelectedParticipant, setSelectedDate]);


    useEffect(() => {
        if (selectedStudy) {
            setStudyLoading(true);
            setParticipantsLoading(true);
            fetchStudyData({ 
                accessToken, selectedStudy, setStudyData, setStudyLoading
            });
            fetchParticipants({
                accessToken, setParticipantsLoading, setParticipants, selectedStudy
            });
            // add study to url
            const url = new URL(window.location.href);
            url.searchParams.set('study', selectedStudy);
        } else {
            setParticipants([]);
        }
    }, [selectedStudy, accessToken, setStudyData, setStudyLoading, setParticipantsLoading, setParticipants]);

    useEffect(() => {
        if (selectedParticipant) {
            setLoadingParticipantData(true);
            fetchParticipantData({ 
                accessToken, setLoadingParticipantData, selectedParticipant,
                setParticipantData, setParticipantSleepData, setParticipantMeasData,
                setColumnsList, setMeasColumnsList
            });
        }
    }, [selectedParticipant, accessToken, setLoadingParticipantData, setParticipantData, setParticipantSleepData, setParticipantMeasData, setColumnsList, setMeasColumnsList]);
    
    useEffect(() => {
        if (selectedParticipant) { 
            fetchParticipantEvents({ accessToken, selectedParticipant, setParticipantEventData })
        }
    }, [accessToken, selectedParticipant, setParticipantEventData]);

    function viewStudyOverview() {
        setSelectedParticipant(null);
        // remove any 'participant' argument from the url
        const url = new URL(window.location.href);
        url.searchParams.delete('participant');
        history.push({search: url.search});
    }

    useEffect(() => {
        console.log('studyData:', studyData);
        console.log('participants:', studyData?.participants);
    }, [studyLoading, studyData])

    return (
      <Box className='print' sx={{ 
        display: 'flex',
        'backgroundColor': theme.palette.background.default, 
        overflow: 'hidden'
      }}>        
        <div>
          <Snackbar
            open={open}
            autoHideDuration={1000}
            message={`Loading...`}
            action={progressWheel}
            sx={{
              position: 'absolute',
              bottom: '1rem',
              left: '1rem',
              backgroundColor: theme.palette.background.paper,
              borderRadius: '10px',
              boxShadow: `0 0 8px ${theme.palette.text.secondary}`
            }}
          />
        </div>
        <Box component="main"
            className='print-area'
            sx={{
                height: '100vh',
                boxSizing: 'border-box',
                justifyContent: 'center',
                alignItems: 'center',
                flexGrow: 1,
                paddingTop: 0, 
                overflowY: 'hidden',
            }}
        >
          <DashNavBar />
        {selectedParticipant ? (
            loadingParticipantData ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
                    <Skeleton variant="rounded" width="95%" height={'95%'} />
                </Box>
            ) : (
              selectedDate ? (
                <Daily loading={{dateLoading, setDateLoading}} />
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
                  <Box 
                      id='dashBox'
                      sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          height: '95%',
                          width: '95%',
                          boxSizing: 'border-box',
                          padding: '2%',
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          boxShadow: `4px 4px 8px ${alpha(theme.palette.text.secondary, 0.5)} inset, -4px -4px 8px #fff inset`,
                          overflowY: 'hidden'
                      }}
                  >
                      <ParticipantCard className='print pData'/>
                      <div className="page-break"></div>
                      <ChartBox/>
                  </Box>
                </Box>
            )
          )
        ) : (
            selectedStudy ? (
                studyLoading ? (
                  <Box sx={{
                    overflow: 'hidden', alignContent: 'center', justifyContent: 'center',
                    width: '100%', display: 'flex', flexDirection: 'columns', height: '90vh',
                    alignItems: 'center', paddingBottom: '2rem', paddingRight: '1rem'
                  }}>
                    <Box>
                        <Typography variant="h4" gutterBottom>
                          {selectedStudy}
                        </Typography>
                        <Typography>
                            Loading data...
                        </Typography>
                        <Typography sx={{ paddingTop: 1 }}>
                          <CircularProgress />
                        </Typography>
                        <Typography sx={{ paddingTop: 2 }}>
                            Search for specific a participant in the top-right corner.
                        </Typography>
                      </Box>
                  </Box>
                ) : (
                  studyData && studyData.participants && studyData.participants.length > 0 ? (
                    <Box sx={{
                      overflowX: 'hidden', alignContent: 'center', justifyContent: 'center',
                      width: '100%', display: 'flex', height: '90vh', alignItems: 'center',
                    }}>
                      {tabIndex === 0 && <StudyDataTable />}
                      {tabIndex === 1 && <StudyDataChartSleep />}
                      {tabIndex === 2 && <StudyDataChartBP />}
                      {tabIndex === 3 && <StudyDataChartWeight />}
                      {tabIndex === 4 && <StudyDataChartBPTrend />}
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="h4" gutterBottom>
                        Study Overview: {selectedStudy}
                      </Typography>
                      <Typography>
                          No data available for this study.
                      </Typography>
                    </Box>
                  )
              )
            ) : (
              <Box>
                <Typography variant="h4" gutterBottom>
                  YawnLabs
                </Typography>
                <Typography variant="h6">Studies</Typography>
                <Paper sx={{ 
                    width: '50%', margin: 'auto', padding: '2rem'
                  }}
                >
                  <TextField
                    fullWidth
                    label="Search Studies"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ marginBottom: '1rem' }}
                  />
                  <Box sx={{ overflowY: 'auto', maxHeight: '400px' }}>
                    {filteredStudies.map((study) => (
                      <Box key={study} sx={{ padding: '0.2rem', display: 'flex', justifyContent: 'center' }}>
                        <Button
                          variant="text"
                          sx={{ 
                            width: '95%', minWidth: '300px', 
                            color: theme.palette.text.primary,
                            backgroundColor:  theme.palette.mode === 'dark' 
                              ? alpha(theme.palette.primary.main, 0.8)
                              : alpha(theme.palette.primary.main, 0.3),
                            boxShadow: `4 4 8px ${theme.palette.text.primary} inset`,
                            '&:hover': { 
                              backgroundColor: theme.palette.mode === 'dark' 
                                ? theme.palette.primary.light 
                                : alpha(theme.palette.primary.main, 0.9),
                              color: theme.palette.text.primary,
                            }
                          }}
                          onClick={() => { 
                            setSelectedStudy(study);
                            let searchParams = new URLSearchParams(window.location.search);
                            searchParams.set('study', study);
                            history.push({search: searchParams.toString()});
                          }}
                        >
                          {study}
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </Paper>
              </Box>
            )
        )}
        </Box>
      </Box>
    );
  };
  
  export default Dashboard;
