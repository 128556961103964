// DataChart.tsx
import React, { useEffect, useRef } from 'react';
import { Chart as ChartJS, ChartType, TooltipPositionerFunction } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-date-fns';
import { format, set } from 'date-fns';
import { useTheme } from '@mui/material/styles';

import { useAuth } from '../../context/AuthContext';
import { useDash } from '../../context/DashContext';

import { Chart } from 'react-chartjs-2';
import { BarController, LineController, CategoryScale,
    LinearScale, TimeScale, BarElement, PointElement,
    LineElement, Tooltip } from 'chart.js';
import { alpha } from '@mui/material';
import zoomPlugin from 'chartjs-plugin-zoom';
import { useParticipant } from '../../context/ParticipantContext';
import { useHistory } from 'react-router-dom';

import moment from 'moment';

ChartJS.register(BarController, LineController, CategoryScale,
    LinearScale, TimeScale, BarElement, PointElement,
    LineElement, annotationPlugin, Tooltip,
    zoomPlugin
);

declare module 'chart.js' {
    interface TooltipPositionerMap {
        bottom: TooltipPositionerFunction<ChartType>;
        average: TooltipPositionerFunction<ChartType>;
    }
  }

Tooltip.positioners.bottom = function(items) {
    const x = items[0]?.element.x;
    return {
        x: x,
        y: 0,
        xAlign: 'center',
        yAlign: 'top',
    };
};


function average(context: any) {
    const values = context.chart.data.datasets[0].data;
    return values.reduce((a: any, b: any) => a + b, 0) / values.length;
}
function rollingAverage(data: any, windowSize: number) {
    let result = [];
    // Calculate the rolling average for each point
    for (let i = 0; i < data.length; i++) {
        let sum = 0;
        // Determine the number of available data points
        let count = Math.min(i + 1, windowSize);
        for (let j = 0; j < count; j++) {
            sum += data[i - j];
        }
        result.push(sum / count);
    }
    return result;
}

interface DataChartData {
    dates: (string | Date)[];
    sleepData: number[];
    dataLabel: string;
    eventDates: (string | Date)[];
    eventNames: string[];
}

interface DataChartProps {
    data: DataChartData;
}

interface AnnotationConfig {
    type: 'line';
    xMin?: string | number;
    xMax?: string | number;
    borderColor: string;
    borderWidth: number;
    borderDash?: number[];
    label?: {
        display: boolean;
        backgroundColor: string;
        content: string;
    };
    scaleID?: string;
    value?: number;
}

interface Annotations {
    [key: string]: AnnotationConfig;
}

const DataChart: React.FC<DataChartProps> = ({ data }) => {
    const theme = useTheme();
    const { showAnnotations, selectedStudy } = useAuth();
    const { participantsLoading, chartRefs, setChartRefs } = useDash();
    const { selectedParticipant, setSelectedDate, setEndDate, setStartDate } = useParticipant();
    const history = useHistory();
    
    const chartRef = useRef(null);
    // Set the chart reference with data.dataLable as the key and the chartRef as the value
    useEffect(() => {
        if (chartRef.current) {
            console.log(`Setting chartRef for ${data.dataLabel}`);
            setChartRefs((chartRefs: any) => {
                return { ...chartRefs, [data.dataLabel]: chartRef.current };
            });
        } else {
            console.warn(`chartRef.current is null for ${data.dataLabel}, retrying...`);
            // Retry setting the chartRef after a short delay
            setTimeout(() => {
                if (chartRef.current) {
                    console.log(`Retrying setting chartRef for ${data.dataLabel}`);
                    setChartRefs((chartRefs: any) => {
                        return { ...chartRefs, [data.dataLabel]: chartRef.current };
                    });
                } else {
                    console.error(`Failed to set chartRef for ${data.dataLabel} after retry`);
                }
            }, 1000); // Retry after 1 second
        }
    }, [data.dataLabel]);

    if (participantsLoading) {
        return <div>Loading...</div>;
    }

    if (data.dataLabel.includes('sleep_timing')) {      
        let start_times = data.sleepData.map((item) => {
            const startDate = new Date(item[0]);
            const endDate = new Date(item[1]);
            let startTime = startDate.getUTCHours() + startDate.getUTCMinutes()/60 + startDate.getUTCSeconds()/3600;
            var endTime = endDate.getUTCHours() + endDate.getUTCMinutes()/60 + endDate.getUTCSeconds()/3600;
            if (endTime < startTime) {
                startTime -= 24;
            }
            return startTime;
        });
        let end_times = data.sleepData.map((item) => {
            const endDate = new Date(item[1]);
            var endTime = endDate.getUTCHours() + endDate.getUTCMinutes()/60 + endDate.getUTCSeconds()/3600;
            return endTime;
        });
        var sleep_times = start_times.map((start, i) => [start, end_times[i]]);
        var avgStartTime = sleep_times.reduce((sum, [start, end]) => sum + start, 0) / sleep_times.length;
        var avgEndTime = sleep_times.reduce((sum, [start, end]) => sum + end, 0) / sleep_times.length;
    }

    ChartJS.defaults.color = theme.palette.text.primary;

    data.dates = data.dates.map((date) => format(new Date(date), 'yyyy-MM-dd'));

    // Check for skipped dates and insert null values to maintain the correct x-axis scale
    const datesSet = new Set(data.dates);
    const missingDates = data.dates.filter((date) => !datesSet.has(date));
    missingDates.forEach((date) => {
        const index = data.dates.indexOf(date);
        data.dates.splice(index, 0, date);
        data.sleepData.splice(index, 0, NaN);
    });

    const rollingAvgData = rollingAverage(data.sleepData, 14);

    let annotations: Annotations = {};

    // Loop through all event dates and names
    for (let i = 0; i < data.eventDates.length; i++) {
        // Use a unique key for each line annotation
        const key = `line${i}`;
        // Convert the date from 'YYYY-MM-DD' to "Tue, 05 Mar 2024 08:49:24 GMT" format
        let eventDateFormatted = format(new Date(data.eventDates[i]), 'yyyy-MM-dd');
        annotations[key] = {
            type: 'line',
            xMin: eventDateFormatted,
            xMax: eventDateFormatted,
            borderColor: '#b30000b3',
            borderWidth: 2,
            label: {
                display: true,
                backgroundColor: '#b30000b3',
                content: data.eventNames[i] // Ensure eventNames corresponds to eventDates by index
            }
        };
    }

    function formatTime(time) {
        if (time < 0) time += 24; // convert negative time to positive
        var hours = Math.floor(time);
        var minutes = Math.round((time % 1) * 60);
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        var minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();
        var strTime = hours.toString().padStart(2, '0') + ':' + minutesStr.padStart(2, '0') + ' ' + ampm;
        return strTime;
    }

    function drawAverageLine(context: any) {
        const ctx = context.chart.ctx;
        const width = context.chart.canvas.width;
        const {x, y, x2, y2, options} = context.element;
        ctx.save();
        ctx.lineWidth = options.borderWidth;
        ctx.strokeStyle = options.borderColor;
        ctx.setLineDash([0, 5]);
        ctx.lineDashOffset = options.borderDashOffset;
        ctx.beginPath();
        ctx.moveTo(0, y);
        ctx.lineTo(x, y);
        ctx.moveTo(x2, y2);
        ctx.lineTo(width, y);
        ctx.stroke();
        ctx.restore();
        return true;
      }

    let datasets = []
    if (data.dataLabel.includes('sleep_timing')) {
        (sleep_times.length > 100) ? console.log('100+') : console.log('')
        console.log(data.dataLabel)
        datasets.push({
            label: data.dataLabel,
            data: sleep_times,
            backgroundColor: alpha(theme.palette.primary.main, 1),
            borderColor: alpha(theme.palette.primary.dark, 1),
            hoverBackgroundColor: alpha(theme.palette.primary.light, 1),
            hoverBorderColor: alpha(theme.palette.primary.dark, 1),
            borderRadius: 5,
            borderWidth: (sleep_times.length > 100) ? 0 : 2,
            borderSkipped: false,
        });
    } else {
        datasets.push({
            label: 'Nightly Data',
            data: data.sleepData,
            // borderColor: "#637ed650",
            borderColor: alpha(theme.palette.primary.main, 0.3),
            backgroundColor: alpha(theme.palette.primary.main, 0.3),
            hoverBorderColor: "#637ed6",
            pointStyle: false,
            cubicInterpolationMode: 'linear',
            borderWidth: (data.sleepData.length > 100) ? 1 : 2,
        },
        {
            label: '7-Day Rolling Average', 
            data: rollingAvgData,
            // borderColor: "#637ed6",
            borderColor: alpha(theme.palette.primary.main, 1),
            backgroundColor: alpha(theme.palette.primary.main, 1),
            pointBackgroundColor: alpha(theme.palette.background.default, 1),
            pointHoverBackgroundColor: alpha(theme.palette.background.default, 1),
            pointHoverRadius: 5,
            fill: false,
            pointRadius: 0,
            cubicInterpolationMode: 'monotone'
        });
    }
    
    const customYearLabelPlugin = {
        id: 'customYearLabel',
        afterDraw: (chart) => {
            const xAxis = chart.scales['x'];
            const ctx = chart.ctx;
            ctx.save();
            ctx.font = '12px Arial';
            ctx.fillStyle = '#666';
            ctx.textAlign = 'left';
    
            let previousYear = null;
            xAxis.ticks.forEach((tick, index) => {
                const date = moment(tick.value);
                const currentYear = date.year();
    
                if (previousYear !== currentYear) {
                    previousYear = currentYear;
                    const x = xAxis.getPixelForTick(index) + 1;
                    const y = chart.chartArea.bottom - 10; // Position below the x-axis
                    ctx.fillText(currentYear.toString(), x, y);
                }
            });
    
            ctx.restore();
        }
    };

    ChartJS.register(customYearLabelPlugin);

    return (
        <div style={{ height: '30vh', width: '100%' }} id={data.dataLabel} className='print r-chart p-chart'>
            <Chart
                type={data.dataLabel.includes('sleep_timing') ? 'bar' : 'line'}
                ref={chartRef}
                data={{
                    labels: data.dates,
                    datasets: datasets
                }}
                options={{
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'day',
                                minUnit: 'day',
                                parser: 'yyyy-MM-dd', // Specify how to parse the dates
                                tooltipFormat: 'EEE, MMMM do yyyy', // Format for the tooltip
                                displayFormats: {
                                    day: 'MMM dd'
                                }
                            },
                            title: {
                                display: false,
                                text: 'Date'
                            },
                            grid: {
                                display: false
                            },
                            ticks: {
                                callback: function(value, index, ticks) {
                                    return moment(value).format('MMM DD');
                                }
                            }
                        },
                        y: {
                            ticks: data.dataLabel.includes('sleep_timing') ? {
                                callback: function(label: number, index, labels) {
                                    let t_val = label
                                    if (label<=0){
                                        t_val = 24+t_val
                                    }
                                    let hours = Math.floor(t_val)
                                    let minutes = Math.round((t_val % 1)*60)
                                    let minutes_str = ''
                                    if (minutes < 10) {
                                        minutes_str = '0' + minutes;
                                    } else {
                                        minutes_str = minutes.toString();
                                    }
                                    return hours+':'+minutes_str;
                                },
                            } : {},
                            title: {
                                display: true,
                                text: data.dataLabel.replace('w_', '').replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
                                font: {
                                    size: 16,
                                    weight: 'bold'
                                }
                            },
                            grid: {
                                display: false
                            }
                        }
                    },
                    transitions: {
                        zoom: {
                            animation: {
                                duration: 0
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: true,
                            backgroundColor: '#1e1e1e',
                            yAlign: 'top',
                            position: 'bottom',
                            callbacks: {
                                label: function(context) {
                                    if (data.dataLabel.includes('sleep_timing')) {
                                        let starttime = context.parsed._custom.start
                                        let endtime = context.parsed._custom.end
                                        return `${formatTime(starttime)} - ${formatTime(endtime)}`;
                                    } else {
                                        let label = context.dataset.label;
                                        let value = context.parsed.y;
                                        return `${label}: ${value.toFixed(2)}`;
                                    };
                                },
                                footer: function(context) {
                                    if (data.dataLabel.includes('sleep_timing')) {
                                        let start = context[0].parsed._custom.start
                                        let end = context[0].parsed._custom.end
                                        return `Time in Bed: ${(end-start).toFixed(2)} hours`;
                                    } else {
                                        return '';
                                    };
                                },
                            }
                        },
                        annotation: {
                            clip: false,
                            annotations: data.dataLabel.includes('sleep_timing') ? {
                                'avgStart': {
                                    type: 'line',
                                    borderColor: alpha(theme.palette.text.primary, 0.6),
                                    borderDash: [6, 6],
                                    borderDashOffset: 0,
                                    borderWidth: 2,
                                    afterDraw: drawAverageLine,
                                    drawTime: 'beforeDatasetsDraw',
                                    label: {
                                        display: showAnnotations ? true : false,
                                        content: 'Average Start: ' + formatTime(avgStartTime),
                                        position: 'center',
                                        backgroundColor: '#1e1e1eb3',
                                        drawTime: 'afterDatasetsDraw',
                                    },
                                    scaleID: 'y',
                                    value: avgStartTime
                                },
                                'avgEnd': {
                                    type: 'line',
                                    borderColor: alpha(theme.palette.text.primary, 0.6),
                                    borderDash: [6, 6],
                                    borderDashOffset: 0,
                                    borderWidth: 2,
                                    afterDraw: drawAverageLine,
                                    drawTime: 'beforeDatasetsDraw',
                                    label: {
                                        display: showAnnotations ? true : false,
                                        content: 'Average End: ' + formatTime(avgEndTime),
                                        position: 'center',
                                        backgroundColor: '#1e1e1eb3',
                                        drawTime: 'afterDatasetsDraw',
                                    },
                                    scaleID: 'y',
                                    value: avgEndTime
                                },
                                'Midnight': {
                                    type: 'line',
                                    borderColor: alpha(theme.palette.text.primary, 0.6),
                                    borderWidth: 1,
                                    afterDraw: drawAverageLine,
                                    drawTime: 'beforeDatasetsDraw',
                                    label: {
                                        display: false
                                    },
                                    scaleID: 'y',
                                    value: 0
                                },
                            } : {
                                'avg': {
                                    type: 'line',
                                    borderColor: alpha(theme.palette.text.primary, 0.6),
                                    borderDash: [6, 6],
                                    borderDashOffset: 0,
                                    borderWidth: 2,
                                    afterDraw: drawAverageLine,
                                    drawTime: 'beforeDatasetsDraw',
                                    label: {
                                        display: showAnnotations ? true : false,
                                        content: (context) => 'Average: ' + average(context).toFixed(2),
                                        position: 'center',
                                        backgroundColor: '#1e1e1eb3',
                                        drawTime: 'afterDatasetsDraw',
                                    },
                                    scaleID: 'y',
                                    value: (context) => average(context)
                                },
                                ...annotations
                            }
                        },
                        zoom : {
                          zoom: {
                            drag: {
                              enabled: true,
                              backgroundColor: alpha(theme.palette.text.secondary, 0.5),
                            },
                            pinch: {
                              enabled: true
                            },
                            mode: 'x' as const,
                            onZoom({chart}) {
                                let min = chart.scales.x.min;
                                let max = chart.scales.x.max;
                                setStartDate(new Date(min));
                                setEndDate(new Date(max));

                                // setChartZoom({min, max});
                                for (let chartKey of Object.keys(chartRefs)) {
                                    let chartRef = chartRefs[chartKey]
                                    if (chartRef !== chart) {
                                        try {
                                            chartRef.zoomScale('x', {min, max}, 'resize');
                                        } catch (error) {
                                            // Handle the error here
                                            console.error('Error occurred while zooming the chart:', error);
                                            console.log(chartRef);
                                            console.log(chartRefs);
                                        }
                                    }
                                };
                            }
                          },
                          pan: {
                            enabled: true,
                            mode: 'x' as const,
                            modifierKey: 'ctrl' as const,
                            onPanComplete({chart}) {
                                let min = chart.scales.x.min;
                                let max = chart.scales.x.max;
                                setStartDate(new Date(min));
                                setEndDate(new Date(max));

                                // setChartZoom({min, max});
                                for (let chartKey of Object.keys(chartRefs)) {
                                    let chartRef = chartRefs[chartKey]
                                    if (chartRef !== chart) {
                                        try {
                                            chartRef.zoomScale('x', {min, max}, 'resize');
                                        } catch (error) {
                                            // Handle the error here
                                            console.error('Error occurred while zooming the chart:', error);
                                            console.log(chartRef);
                                            console.log(chartRefs);
                                        }
                                    }
                                };
                            }
                          },
                        }
                    },
                    interaction: {
                        mode: 'index',
                        intersect: false,
                        axis: 'x'
                    },
                    onClick: (event: any, elements: any) => {
                        if (elements.length > 0) {
                            const elementIndex = elements[0].index;
                            const date = data.dates[elementIndex];
                            const url = `/dashboard?study=${selectedStudy}&participant=${selectedParticipant}&date=${date}`;
                            const baseUrl = history.location.pathname.split('/dashboard')[0];
                            setSelectedDate(new Date(date));
                            history.push(baseUrl + url);
                        }
                      },
                    spanGaps: 1000 * 60 * 60 * 24 * 7,
                    maintainAspectRatio: false,
                    responsive: true,
                    animation: false
                }}
            />
        </div>
    );
};

export default DataChart;