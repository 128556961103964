import { 
  Box, FormControl, InputLabel, Select, MenuItem, TextField,
  Checkbox, ListItemText, Button, ButtonGroup, ToggleButton,
  ToggleButtonGroup, IconButton, Paper, alpha,
  inputClasses
} from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import DataChart from "./data_withings";
import { useParticipant } from '../../context/ParticipantContext';
import { useDash } from "../../context/DashContext";
import { useAuth } from "../../context/AuthContext";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import ClearIcon from '@mui/icons-material/Clear';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';

import { useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};


const ChartBox: React.FC = () => {

    const { filteredSleepData, filteredEventData,
            setStartDate, setEndDate, earliestDate, latestDate
     } = useParticipant();
    const { columnsList, chartRefs, isCardExpanded } = useDash();
    const { selectedColumns, setSelectedColumns, showAnnotations, setShowAnnotations } = useAuth();

    const theme = useTheme();

    // when selectedColumns is updated, if a chartRefs key is not in selectedColumns, remove it from chartRefs
    useEffect(() => {
      if (chartRefs === undefined) return;
      for (let column of Object.keys(chartRefs)) {
          if (!selectedColumns.includes(column)) {
            if (column !== 'w_sleep_timing') {
              delete chartRefs[column];
            }
          }
      }
    }, [selectedColumns]);

    return (
    <Box id='chartsMain' sx={{ 
      width: '100%', overflowY: 'scroll', overflowX: 'hidden',
      margin: '1%', marginTop: '0%', position: 'relative'
      }}>
        <Box
          sx={{
            display: 'flex', justifyContent: 'space-between',
            padding: '0.2%', overflow: 'show', zIndex: 1,
          }}
        >
        <FormControl sx={{ 
            width: isCardExpanded ? '10%' : '20%',
          }} size="small">
              <InputLabel id="column-select-label" 
                sx = {{
                  color: theme.palette.text.secondary,
                  fontWeight: 'bold',
                }}>Charts</InputLabel>
              <Select
                  sx = {{
                    // backgroundColor: alpha(theme.palette.text.secondary, 0.6),
                    // borderColor: alpha(theme.palette.text.secondary, 0.1),
                    // color: theme.palette.primary.contrastText,
                    // boxShadow: '2px 5px 5px 0px ' + alpha("000", 0.3),
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    color: theme.palette.text.secondary,
                    boxShadow: 'none',
                  }}
                  labelId="column-select-label"
                  id="column-multiple-checkbox"
                  multiple
                  value={selectedColumns}
                  onChange={(event) => {
                      const value = event.target.value;
                      // On autofill we get a stringified value.
                      setSelectedColumns(typeof value === 'string' ? value.split(',') : value);
                  }}
                  // renderValue={(selected) => selected.map(column => column.replace('w_', '').replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())).join(', ')}
                  renderValue={(selected) => selected.length === 0 ? null : `${selected.length} selected`}
                  displayEmpty
                  MenuProps={MenuProps}
              >
                  {columnsList.map((column) => (
                      <MenuItem key={column} value={column}>
                          <Checkbox checked={selectedColumns.indexOf(column) > -1} />
                          <ListItemText primary={column.replace('w_', '').replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())} />
                      </MenuItem>
                  ))}
              </Select>
          </FormControl>
          <ButtonGroup
            variant='contained'
            sx = {{
              // backgroundColor: alpha(theme.palette.text.secondary, 0.6),
              // borderColor: alpha(theme.palette.text.secondary, 0.1),
              // color: theme.palette.primary.contrastText,
              // boxShadow: '2px 5px 5px 0px ' + alpha("000", 0.3),
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              color: theme.palette.text.secondary,
              boxShadow: 'none',
            }}
          >
            <IconButton 
              className="noPrint"
              title="Toggle Annotations"
              onClick={() => setShowAnnotations(!showAnnotations)}
            >
              {showAnnotations ? <SpeakerNotesIcon /> : <SpeakerNotesOffIcon />}
            </IconButton>
            <IconButton 
              className="noPrint"
              title="Reset Zoom"
              onClick={() => {
                setStartDate(earliestDate);
                setEndDate(latestDate);
              }}
            >
              <CenterFocusWeakIcon />
            </IconButton>
            <IconButton 
              className="noPrint"
              title="Clear Selection"
              onClick={() => setSelectedColumns([])}
            >
              <ClearIcon />
            </IconButton>
          </ButtonGroup>
        </Box>
        <Box id="dataCharts" sx={{ marginTop: '5%' }}>
            <Box key={'w_sleep_timing'} sx={{ height: '30vh', margin: '2%' }} className="chartBox">
                <DataChart
                    data={{
                        dates: filteredSleepData.map((item) => item.w_enddate),
                        sleepData: filteredSleepData.map((item) => item['w_sleep_timing']),
                        dataLabel: 'w_sleep_timing',
                        eventDates: filteredEventData.map((item) => item.start_date),
                        eventNames: filteredEventData.map((item) => item.name),
                    }}
                />
            </Box>
            {selectedColumns.map((selectedColumn: any) => (
                <Box key={selectedColumn} sx={{ height: '30vh', margin: '2%' }} className="chartBox">
                    <DataChart
                        data={{
                            dates: filteredSleepData.map((item) => item.w_enddate),
                            sleepData: filteredSleepData.map((item) => item[selectedColumn]),
                            dataLabel: selectedColumn,
                            eventDates: filteredEventData.map((item) => item.start_date),
                            eventNames: filteredEventData.map((item) => item.name),
                        }}
                    />
                </Box>
            ))}
        </Box>
    </Box>
    );
}

export default ChartBox;