import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import CryptoJS from 'crypto-js';
import { fetchDashPreferences } from '../functions/snapi';

interface AuthContextType {
  isLoading: boolean;
  accessToken: string | null;
  setAccessToken: (accessToken: string | null) => void;
  selectedStudy: string;
  setSelectedStudy: (study: string) => void;
  studyData: any | null;
  setStudyData: React.Dispatch<React.SetStateAction<any>>;
  participants: string[];
  setParticipants: (participants: string[]) => void;
  studies: string[];
  setStudies: (studies: string[]) => void;
  selectedColumns: string[];
  setSelectedColumns: (columns: string[]) => void;
  showAnnotations: boolean;
  setShowAnnotations: (showAnnotations: boolean) => void;
  userSettings: any;
  setUserSettingsState: React.Dispatch<React.SetStateAction<any>>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY

interface AuthProviderProps {
  children: ReactNode; // This line explicitly types the children prop
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [accessToken, setAccessTokenState] = useState<string | null>(null);
  const [selectedStudy, setSelectedStudy] = useState<string>('');
  const [studyData, setStudyData] = useState<any>(null);
  const [participants, setParticipants] = useState<string[]>([]);
  const [studies, setStudies] = useState<string[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [showAnnotations, setShowAnnotations] = useState(true);
  const [userSettings, setUserSettingsState] = useState<any>({});

  const setAccessToken = async (token: string | null) => {
    if (token) {
      const encryptedToken = CryptoJS.AES.encrypt(token, SECRET_KEY).toString();
      localStorage.setItem('accessToken', encryptedToken);
    } else {
      localStorage.removeItem('accessToken');
    }
    setAccessTokenState(token);
  };

  // Decrypt token and userSettings on initial load
  useEffect(() => {
    const encryptedToken = localStorage.getItem('accessToken');
    const sessionSettings = JSON.parse(localStorage.getItem('userSettings'));
    if (encryptedToken) {
      const bytesToken = CryptoJS.AES.decrypt(encryptedToken, SECRET_KEY);
      const originalToken = bytesToken.toString(CryptoJS.enc.Utf8);
      setAccessTokenState(originalToken);
    }
    if (sessionSettings) {
      setUserSettingsState(sessionSettings);
    }
    setIsLoading(false);
  }, []);

  // Fetch user settings on initial load
  useEffect(() => {
    if (accessToken) {
      fetchDashPreferences({ accessToken }).then((data) => {
        if (data) {
          setUserSettingsState(data);
          localStorage.setItem('userSettings', JSON.stringify(data));
        }
      });
    }
  }, [accessToken]);

  return (
    <AuthContext.Provider value={{
        isLoading, accessToken, setAccessToken, selectedStudy, setSelectedStudy, 
        studyData, setStudyData, userSettings, setUserSettingsState,
        participants, setParticipants, studies, setStudies,
        selectedColumns, setSelectedColumns, showAnnotations, setShowAnnotations
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
