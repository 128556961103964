import React from 'react';
import { AuthProvider } from './context/AuthContext';
import { ParticipantProvider } from './context/ParticipantContext';
import { CustomThemeProvider } from './context/themeContext';
import { DashProvider } from './context/DashContext';
import { QueueProvider } from './context/QueueContext';

const Providers: React.FC<{children: React.ReactNode}> = ({ children }) => {
  return (
    <AuthProvider>
      <DashProvider>
        <QueueProvider>
          <ParticipantProvider>
            <CustomThemeProvider>
              {children}
            </CustomThemeProvider>
          </ParticipantProvider>
        </QueueProvider>
      </DashProvider>
    </AuthProvider>
  );
};

export default Providers;