import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Adjust the path based on your file structure

interface PrivateRouteProps extends RouteProps {
    children: React.ReactNode;
}
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
    const { isLoading, accessToken } = useAuth();
    if (isLoading) {
      return <></>;
    } else {
      return (
        <Route
          {...rest}
          render={({ location }) =>
            accessToken ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location }
                }}
              />
            )
          }
        />
      );
    };
  };
  export default PrivateRoute;
