import { 
  useTheme, Box, Typography, useMediaQuery,
  List, ListItem, ListItemText, Collapse, Button 
} from '@mui/material';

import useDocumentTitle from '../functions/useDocumentTitle';
import ResponsiveAppBar from '../components/NavBar';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react';

const Documentation: React.FC = () => {
  useDocumentTitle('YawnLabs | Documentation');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState<{ [key: string]: boolean }>({});

  // Function to handle dropdown click
  const handleClick = (name: string) => {
    setOpen(prev => ({ ...prev, [name]: !prev[name] }));
  };

  // Function to open the HTML document
  const openDocument = (url: string) => {
    window.open(url, '_blank');
  };

  // Example structure for documents
  const documents = [
    {
      category: 'Devices',
      items: [
        {
          subCategory: 'Withings',
          docs: [
            { name: 'Account Setup', url: '/docs/withings/setup/Accounts.html'},
            { name: 'Mobile Hotspot', url: '/docs/withings/setup/Mobile Hotspot.html' },
            { name: 'Sleep Data', url: '/docs/withings/data/Sleep Data.html' }
            // Add more documents here
          ]
        }
        // Add more subCategories here
      ]
    }
    // Add more categories here
  ];

  return (
    <Box
      sx={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%'
      }}
      >
        <ResponsiveAppBar currentPage='Home' />
        <Box sx={{ height: theme.spacing(2) }} />

        <Box sx={{
            paddingTop: theme.spacing(2),
            width: isMobile ? '30%' : '100%'
            }}
        >
          <Typography variant="h4">
          Documentation
          </Typography>

        <Box sx={{ height: theme.spacing(4) }} />

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
         }} >
          <List
            component="nav" aria-labelledby="nested-list-subheader"
            sx={{ 
              width: '90%', maxWidth: 360,
              bgcolor: 'background.paper', 
              overflow: 'auto', maxHeight: 300 
            }}>
            {documents.map((doc, index) => (
              <React.Fragment key={index}>
                <ListItem button onClick={() => handleClick(doc.category)}>
                  <ListItemText primary={doc.category} />
                  {open[doc.category] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open[doc.category]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {doc.items.map((item, itemIndex) => (
                      <ListItem button sx={{ pl: 4 }} key={itemIndex} onClick={() => handleClick(item.subCategory)}>
                        <ListItemText primary={item.subCategory} />
                        {open[item.subCategory] ? <ExpandLess /> : <ExpandMore />}
                        <Collapse in={open[item.subCategory]} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {item.docs.map((doc, docIndex) => (
                              <ListItem button sx={{ pl: 8 }} key={docIndex}>
                                <Button onClick={() => openDocument(doc.url)}>{doc.name}</Button>
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default Documentation;